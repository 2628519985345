import React, { Component } from 'react';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

class CreateUser extends Component {
    constructor(props) {
        super(props)
        this.state = {
            firstName: "",
            middleInitial: '',
            lastName: "",
            email: "",
            phoneNumber: "",
            rank: "",
            supervisor: "",
            role: "",
            organization: "",
            address: "",
            city: "",
            state: "",
            zipCode: "",
            birthdate: "",
            sex: ''
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    handleSubmit(evt) {
        evt.preventDefault()
        const { firstName, lastName, email, phoneNumber, rank, supervisor, role, organization, address, city, state, zipCode, birthdate, sex } = this.state;
        axios.post('/api/roster', { firstName, lastName, email, phoneNumber, rank, supervisor, role, organization, address, city, state, zipCode, birthdate, sex })
        .then(res => {
            this.props.history.push('/userlist');
        });
    }

    render() {
        return (
            <div >
<form className="createForm" onSubmit={this.handleSubmit}>
    <Grid container spacing={3}>
        <Grid container
            direction="row"
            justify="center"
            alignItems="center" xs={12}>
            <Typography variant="h4" >
                Add a New User:
</Typography>
        </Grid>
        <Grid className="gridMarginAlt" container
            direction="row"
            justify="center"
            alignItems="center" xs={12}>
            <TextField
                className="textFieldAlt"
                id="createUserFirstName"
                name="firstName"
                label="First Name"
                placeholder="Ex: Jane"
                multiline
                variant="outlined"
                onChange={this.handleChange}
            />
        </Grid>
        <Grid className="gridMarginAlt" container
            direction="row"
            justify="center"
            alignItems="center" xs={12}>
            <TextField
                className="textFieldAlt"
                id="createUserMiddleInitial"
                name="middleInitial"
                label="Middle Initial"
                multiline
                variant="outlined"
                onChange={this.handleChange}
            />
        </Grid>
        <Grid className="gridMarginAlt" container
            direction="row"
            justify="center"
            alignItems="center" xs={12}>
            <TextField
                className="textFieldAlt"
                id="createUserLastName"
                name="lastName"
                label="Last Name"
                placeholder="Ex: Smith"
                multiline
                variant="outlined"
                onChange={this.handleChange}
            />
        </Grid>
        <Grid className="gridMarginAlt" container
            direction="row"
            justify="center"
            alignItems="center" xs={12}>
            <TextField
                className="textFieldAlt"
                id="createUserEmail"
                label="User Email"
                name="email"
                placeholder="Ex: jsmith@oiems.com"
                multiline
                variant="outlined"
                onChange={this.handleChange}
            />
        </Grid>
        <Grid className="gridMarginAlt" container
            direction="row"
            justify="center"
            alignItems="center" xs={12}>
            <TextField
                className="textFieldAlt"
                id="createUserPhone"
                label="User Phone number"
                name="phoneNumber"
                placeholder="Ex: 123-456-7890"
                multiline
                variant="outlined"
                onChange={this.handleChange}
            />
        </Grid>
        <Grid className="gridMarginAlt" container
            direction="row"
            justify="center"
            alignItems="center" xs={12}>
            <TextField
                className="textFieldAlt"
                id="createUserRank"
                label="User Rank"
                name="rank"
                placeholder="Ex: Officer"
                multiline
                variant="outlined"
                onChange={this.handleChange}
            />
        </Grid>
        <Grid className="gridMarginAlt" container
            direction="row"
            justify="center"
            alignItems="center" xs={12}>
            <TextField
                className="textFieldAlt"
                id="createUserRole"
                label="User Role"
                name="role"
                placeholder="Ex: EMT"
                multiline
                variant="outlined"
                onChange={this.handleChange}
            />
        </Grid>
        <Grid className="gridMarginAlt" container
            direction="row"
            justify="center"
            alignItems="center" xs={12}>
            <TextField
                className="textFieldAlt"
                id="createUserSupervisor"
                label="User Supervisor"
                name="supervisor"
                placeholder="Ex: Jessica James"
                multiline
                variant="outlined"
                onChange={this.handleChange}
            />
        </Grid>
        <Grid className="gridMarginAlt" container
            direction="row"
            justify="center"
            alignItems="center" xs={12}>
            <TextField
                className="textFieldAlt"
                id="createUserOrganization"
                name="organization"
                label="User Organization"
                placeholder="Ex: Police"
                multiline
                variant="outlined"
                onChange={this.handleChange}
            />
        </Grid>
        <Grid className="gridMarginAlt" container
            direction="row"
            justify="center"
            alignItems="center" xs={12}>
            <TextField
                className="textFieldAlt"
                id="createUserAddress"
                name="address"
                label="User street address"
                placeholder="123 Main St."
                multiline
                variant="outlined"
                onChange={this.handleChange}
            />
        </Grid>
        <Grid className="gridMarginAlt" container
            direction="row"
            justify="center"
            alignItems="center" xs={12}>
            <TextField
                className="textFieldAlt"
                id="createUserCity"
                name="city"
                label="User city"
                placeholder="Fairfax"
                multiline
                variant="outlined"
                onChange={this.handleChange}
            />
        </Grid>
        <Grid className="gridMarginAlt" container
            direction="row"
            justify="center"
            alignItems="center" xs={12}>
            <TextField
                className="textFieldAlt"
                id="createUserState"
                name="state"
                label="User state"
                placeholder="Virginia"
                multiline
                variant="outlined"
                onChange={this.handleChange}
            />
        </Grid>
        <Grid className="gridMarginAlt" container
            direction="row"
            justify="center"
            alignItems="center" xs={12}>
            <TextField
                className="textFieldAlt"
                id="createUserZip"
                name="zipCode"
                label="User Zip Code"
                placeholder="20001"
                multiline
                variant="outlined"
                onChange={this.handleChange}
            />
        </Grid>
        <Grid className="gridMarginAlt" container
            direction="row"
            justify="center"
            alignItems="center" xs={12}>
            <TextField
                className="textFieldAlt"
                id="createUserBirthdate"
                name="birthdate"
                label="User Birthdate"
                type="date"
                defaultValue="1999-05-24"
                variant="outlined"
                onChange={this.handleChange}
            />
        </Grid>
        <Grid className="gridMarginAlt" container
            direction="row"
            justify="center"
            alignItems="center" xs={12}>
            <TextField
                className="textFieldAlt"
                id="createUserSex"
                name="sex"
                label="User Sex"
                placeholder="Ex: M/F"
                multiline
                variant="outlined"
                onChange={this.handleChange}
            />
        </Grid>
        <Grid container
            direction="row"
            justify="center"
            alignItems="center" xs={12}>
            {/* <input value="Upload" type="submit"></input> */}
            <Button variant="outlined"  size="large" aria-label="Add Event" type="submit" >
                Submit
  </Button>
        </Grid>
    </Grid>
</form>
</div>
        )
    }
}

export default CreateUser;
