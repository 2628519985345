
function getFormattedDate(dateString) {

    if(dateString) {
        const date = new Date(dateString)
        var year = date.getFullYear();
        
        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;
        
        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
            
        var fullDate = month + '/' + day + '/' + year;
    } else {
        var fullDate = "";
    }
    return fullDate
}

//getformatteddatetime
function getFormattedDateTime(dateString) {

    if(dateString) {
        const date = new Date(dateString)
        var year = date.getFullYear();
    
        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;
    
        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        var minutes = date.getMinutes().toString();
        minutes = minutes.length > 1 ? minutes : '0' + minutes;

        var hours = date.getHours().toString();
        hours = hours.length > 1 ? hours : '0' + hours;

        var time = hours + ':' + minutes;
        
        var fullDateTime = month + '/' + day + '/' + year + ' ' + time;
    } else {
        var fullDateTime = "";
    }
    return fullDateTime
} 

export { getFormattedDate, getFormattedDateTime };