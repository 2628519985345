import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
//import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import ChipInput from 'material-ui-chip-input';
import ListItemText from '@material-ui/core/ListItemText';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import EvidentHelper from '../scripts/evidentHelper';
import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import ConfirmDialog from './ConfirmDialog'

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import EventMap from './EventMap';
// import { TableContainer, Toolbar } from '@material-ui/core';
import { getFormattedDate } from '../FormatDate';
import ImportResource from './ImportResource';
import { getFormattedDateTime } from '../FormatDate';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = theme => ({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        float: 'left',
    },
    pos: {
        marginBottom: 12,
    },
    linkMargin: {
        margin: '5px',
    },
    btnStyle: {
        textDecoration: 'none',
    },
    appHeader: {
        backgroundColor: '#192d56',
      },
    mapStyle: {
        width: '50%',
        height: '540px'
    },
    appBarButton: {
        color: 'white',
        border: '1px solid white',
        margin: '5px',
      }
});
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#12779f',
        color: theme.palette.common.white,
        fontSize: '1.25em',
    },
    body: {
      
        fontSize: '1.25em',
      
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        color: 'slategray',
        '&:nth-of-type(odd)': {
            // backgroundColor: ''theme.palette.action.hover'',
            backgroundColor: '#F3F4F8',
        },
        '&:nth-of-type(even)': {
          backgroundColor: '#E6E8E9',
      },
      
      '&:hover': {
        backgroundColor: 'white',
        cursor: 'pointer',
      
        },
        '& a': {
          color: '#3d3d3d',
        },
        '&:hover a': {
          color: '#12779f',
        },
    },
}))(TableRow);

class EventCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            row: {},
            id: this.props.match.params.id,
            name: '',
            description: '',
            type: '',
            start: '',
            end: '',
            location: JSON.stringify({
                name: "",
                map_region: {
                    latitude: 0.0,
                    longitude:0.0,
                    latitudeDelta: 0.1,
                    longitudeDelta: 0.1,
                },
                perimeter_polygon: [],
                points_of_interest: []
            }),
            status: '',
            roster: [],
            readOnly: true,
            rows: [],
            checked: [],
            toDelete: [],
            value: Number(this.props.match.params.tab) || 0
        } ;
        
        this.onSubmit = this.onSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    updateList = () => {
        axios.get('/api/upload_file/' + this.state.id)
        .then(res => {
            const data = res.data.upload;
            this.setState({ rows: data});
            data.forEach(item => {
                let checkedItems = this.state.checked.concat(false);
                this.setState({ checked: checkedItems });
            });
        });
    }

    onDelete = () => {

        this.state.toDelete.forEach(item => {
            axios.delete('/api/upload_file/' + item)
            .then(res => {
                console.log(res)
            });
        });
        this.updateList();
     }

    onSubmit = () => {
        const params = JSON.stringify({
            name: this.state.name,
            type: this.state.type,
            description: this.state.description,
            start: this.state.start,
            end: this.state.end,
            location: this.state.row.location,
            status: this.state.status,
            roster: this.state.roster
        });
    }

    handleChange = (evt) => {
        evt.preventDefault();
        this.setState({ [evt.target.name]: evt.target.value });
    }

    handleTabChange = (id) => {
        this.setState({ value: id });
    }


    async componentDidMount () {
        
        await axios.get('/api/event/' + this.state.id)
            .then(res => {
                const data = res.data.event;
                this.setState({ row: data });
            });

        await axios.get('/api/event/getroster/' + this.state.id)
            .then(res => {
                let roster = [];
                for(const user of res.data.members){
                    //console.log("user is: ", user);
                    roster.push(user);
                }
            
                this.setState({ roster: roster });
            });
        
        await axios.get('/api/upload_file/' + this.state.id)
        .then(res => {
            const data = res.data.upload;
            this.setState({ rows: data });
            data.forEach(item => {
                let checkedItems = this.state.checked.concat(false);
                this.setState({ checked: checkedItems });
            });
        });
    }

    getVerificationStatus = (userInfo) => {
        console.log(userInfo);
        if (userInfo.verification)
        {
          // Mapper is needed for proper statuses
          return EvidentHelper.mapStatus(userInfo.verification.status);
        }  
    }

    setConfirmOpen = (state) => {
        this.setState( { confirmOpen: state });
    }

    checkboxChanged = (rowId, index, checked) => {
        let { toDelete } = this.state;
        let rowIsChecked = this.state.checked;
        rowIsChecked[index] = checked;
        let array = [ ...toDelete ];
        let _this = this;
        if(checked === true) {
            if (toDelete.some(item => rowId === item) === false) {
                array.push(rowId);
                _this.setState({ checked: rowIsChecked, toDelete: array });
            }
        } else {
            if(toDelete.some(item => rowId === item)) {
                var index = array.indexOf(rowId);
                if (index !== -1) {
                    array.splice(index, 1);
                    _this.setState({ checked: rowIsChecked, toDelete: array });
                }
            }
        }
    }

    render() {
        const { classes } = this.props;

        const TabPanel = (event) => {
            const { value } = this.state;
            const children = event.children;
            const index = event.index;
            
            return (
                <div
                  role="tabpanel"
                  hidden={value !== index}
                  id={`full-width-tabpanel-${index}`}
                  aria-labelledby={`full-width-tab-${index}`}
                >
                  {value === index && ( 
                    <Typography>{children}</Typography>
                  )}
                </div>
              );
        };

        const RosterTable = () => {

            return (
                <TableContainer>
                    <AppBar position="static" className={classes.appHeader}>
                        <Toolbar>
                            <Grid justify='space-between' container spacing={24}>
                                <Grid item>
                                    <Typography variant="h4" className={classes.title}>
                                        Event Roster
                                    </Typography>
                                </Grid>
                                <Grid container direction="row" justify="flex-end" alignItems="center" xs={8}>
                                    {/* <div className={classes.search}>
                                    <div className={classes.searchIcon}>
                                        <SearchIcon />
                                    </div>
                                    <InputBase 
                                        placeholder="Search.."
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput,
                                        }}
                                        inputProps={{ 'aria-label': 'search' }}
                                        />
                                    </div> */}
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <Table className={classes.table} aria-label="roster list table">
                    <TableHead>
                            <TableRow>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell>Rank</StyledTableCell>
                                <StyledTableCell>Email</StyledTableCell>
                                <StyledTableCell>Organization</StyledTableCell>
                                <StyledTableCell>Specialization</StyledTableCell>
                                <StyledTableCell>Verification</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {/* maps through users (rows) in order to get index of each user for check functionality  */}
                            {this.state.roster && this.state.roster.map((row, index) => (
                                <StyledTableRow key={row.name}> 
                                <StyledTableCell  component="th" scope="row">
                            
                                {row.lastName}, {row.firstName}
                            
                                </StyledTableCell>
                                <StyledTableCell >
                            
                                {row.rank}
                            
                                </StyledTableCell>
                                <StyledTableCell >
                            
                                {row.email}
                                
                                </StyledTableCell>
                                <StyledTableCell >
                            
                                {row.organization}
                                
                                </StyledTableCell>
                                <StyledTableCell >
                            
                                {row.role}
                        
                                </StyledTableCell>
                                <StyledTableCell >
    
                                {this.getVerificationStatus(row)}
                            
                                </StyledTableCell>
                            </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>  
            );
        };

        //resource table
        const ResourceTable = () => {
            
            return (
                <TableContainer component={Paper}>
                    <AppBar position="static" className={classes.appHeader}>
                        <Toolbar>
                            <Grid justify='space-between' container spacing={24}>
                                <Grid item>
                                    <Typography variant="h4" className={classes.title}>
                                        List of Resources
                                    </Typography>
                                </Grid>
                                <Grid container direction="row" justify="flex-end" alignItems="center" xs={8}>
                                    {/* <div className={classes.search}>
                                    <div className={classes.searchIcon}>
                                        <SearchIcon />
                                    </div>
                                    <InputBase 
                                        placeholder="Search.."
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput,
                                        }}
                                        inputProps={{ 'aria-label': 'search' }}
                                        />
                                    </div> */}
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <Table className={classes.table} aria-label="resource list table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Select</StyledTableCell>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell>Size</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.rows && this.state.rows.map((row, index) => {
                                let isChecked = this.state.checked[index];

                                return (
                                    <StyledTableRow key={row.name}>
                                        <TableCell>
                                            <Checkbox
                                                key={index}
                                                checked={isChecked}
                                                onChange={e => this.checkboxChanged(row.id, index, e.target.checked)}/>
                                        </TableCell>
                                        <StyledTableCell>
                                           {row.name}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {row.size}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }

        return (
            <Card className={classes.root}variant="filled">
                <CardContent justify='center'>
                    <form>
                        <Grid>
                            <Tabs value={this.state.value} aria-label="change component">
                                <Tab label="Event Details" id='0' onClick={()=>this.handleTabChange(0)}/>
                                <Tab label="Event Roster" id='1' onClick={()=>this.handleTabChange(1)}/>
                                <Tab label="Event Map" id='2' onClick={()=>this.handleTabChange(2)}/>
                                <Tab label="Resources" id='3' onClick={()=>this.handleTabChange(3)}/>
                            </Tabs>
                            <TabPanel value={this.state.value} index={0}>
                                <Grid container
                                    direction="row"
                                    justify="center"
                                    alignItems="center" xs={12}>
                                    <Typography variant="h4" >
                                        Event Details
                                    </Typography>
                                </Grid>

                                <Grid className="gridMarginAlt" container
                                    direction="row"
                                    justify="center"
                                    alignItems="center" xs={12}>
                                    <TextField className="textFieldAlt" InputProps={{ readOnly: true, }}
                                        label="Event Name"
                                    variant="filled"
                                        name="name"
                                        value={this.state.row.name}
                                        InputLabelProps={{ shrink: true }} onInput={this.handleChange} />
                                </Grid>

                                <Grid className="gridMarginAlt" container
                                    direction="row"
                                    justify="center"
                                    alignItems="center" xs={12}>
                                    <TextField className="textFieldAlt" InputProps={{ readOnly: true, }}
                                        label="Event Description"
                                        variant="filled"
                                        multiline
                                        rows={4}
                                        name="description"
                                        value={this.state.row.description}
                                        InputLabelProps={{ shrink: true }} onInput={this.handleChange} />
                                </Grid>

                                <Grid className="gridMarginAlt" container
                                    direction="row"
                                    justify="center"
                                    alignItems="center" xs={12}>
                                    <TextField className="textFieldAlt" InputProps={{ readOnly: true, }}
                                        label="Event Type"
                                    variant="filled"
                                        name="type"
                                        value={this.state.row.type}
                                        InputLabelProps={{ shrink: true }} onInput={this.handleChange} />
                                </Grid>


                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <TextField className="textFieldAlt" InputProps={{ readOnly: true, }}
                                    label="Start time"
                                    variant="filled"
                                    name="start"
                                    value={getFormattedDateTime(this.state.row.start)}
                                    InputLabelProps={{ shrink: true }} onInput={this.handleChange} />
                            </Grid>


                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <TextField className="textFieldAlt" InputProps={{ readOnly: true, }}
                                    label="End time"
                                    variant="filled"
                                    name="end"
                                    value={getFormattedDateTime(this.state.row.end)}
                                    InputLabelProps={{ shrink: true }} onInput={this.handleChange} />
                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <TextField className="textFieldAlt" 
                                    InputProps={{ readOnly: true, }}
                                    label="Status"
                                    variant="filled"
                                    value={this.state.row.status}
                                    InputLabelProps={{ shrink: true }} />
                            </Grid>
                            </TabPanel>

                            <TabPanel value={this.state.value} index={1}>
                                <Grid className="gridMarginAlt" container
                                    direction="row"
                                    justify="center"
                                    alignItems="center" xs={12}>
                                    <RosterTable />
                                </Grid>
                            </TabPanel>
                            <TabPanel value={this.state.value} index={2}>
                                <Grid className="gridMarginAlt" container
                                    direction="row"
                                    justify="center"
                                    alignItems="center" xs={12}
                                    style={useStyles.mapStyle}>
                                    <EventMap eventId={this.state.id} readOnly={true} />
                                </Grid>
                            </TabPanel>
                            <TabPanel value={this.state.value} index={3}>
                                <Grid className="gridMarginAlt" container
                                    direction="row"
                                    justifty="center"
                                    alignItems="center" xs={12}>
                                    {/* <ImportResource /> */}
                                    <ResourceTable />
                                </Grid>
                            </TabPanel>                    
                            <CardActions>
                                <Grid className="gridMarginAlt" container
                                    spacing={2}
                                    direction="row"
                                    justify="center"
                                    alignItems="center" xs={12}>
                                    <Grid item>
                                        <NavLink  style={{ textDecoration: 'none' }} to='/events'>
                                            <Button color="secondary" size="large" variant="outlined">Cancel</Button>
                                        </NavLink>
                                    </Grid>
                                    <Grid item>
                                        <NavLink  style={{ textDecoration: 'none' }} to={`/manageevent/${this.state.row.id}/${this.state.value}/${this.state.row.name}/${this.state.row.description}/${this.state.row.type}/${this.state.row.start}/${this.state.row.end}/${JSON.stringify(this.state.row.location)}/${this.state.row.status}`}>
                                            <Button className="btnStyle" color="primary" size="large" variant="outlined" onClick={this.onSubmit}>Edit</Button>
                                        </NavLink>
                                    </Grid>
                                </Grid>
                            </CardActions>
                        </Grid>
                    </form>
                </CardContent>
                {/*<CardActions>
                    <Button size="small">Event Details</Button>
                </CardActions>*/}
            </Card>
        )
    }
}
export default withStyles(useStyles, { withTheme: true })(EventCard);
