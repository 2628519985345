import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PublishIcon from '@material-ui/icons/Publish';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import axios from 'axios';


const overideStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,

    },
    grids: {
        margin: '15px'
    },
    textField: {
        width: '25%',
    },
}));

 
class ImportPlan extends Component {

    constructor(props) {
        super(props)
        this.state = {
            id: '',
            checked: false,
            name: '',
            members: []
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSkip = this.handleSkip.bind(this); 
    }

    async componentDidMount(){
        const cid = this.props.match.params.id;
        const name = this.props.match.params.name;
    
        this.setState({ id: cid, name: name });
    }

    handleSkip(){
        this.props.history.push('/createeventgroup/' + this.state.id);
    }

    async handleSubmit(evt) {
        evt.preventDefault();
        
        const formData = new FormData();
        const plan = document.querySelector('#myFile');
        const members = [];
        
        formData.append("id", this.state.id);
        formData.append("json", plan.files[0]);

        await axios.post('/api/upload/', formData, {
            headers: {
            'Content-Type': 'multipart/form-data'
            }
        })
        .then(res => {
            if(res.personnel){
                members = res.personnel;
            }
        })
        .catch(error =>{
            console.log(error);
        });
        
        const name = this.state.name;
        const description = `Master ${name} group`;
        const makeChannel = true;
        if(this.state.checked){
            await axios.post('/api/groups', { name, description, members, makeChannel })
            .then(res => {
                console.log(res);
            })
            .catch(err => {
                console.log(err);
            });
        }

        if(plan){
            this.props.history.push('/createeventgroup/' + this.state.id);
        }
    }  
    
    render(){
        const { classes } = this.props;

        return (
            // <div className='ImportPlan'>
            <div className={classes.root}>
                <form action="/api/upload/" method="post" enctype="multipart/form-data" onSubmit={this.handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid container
                            direction="row"
                            justify="center"
                            alignItems="center" xs={12}>
                            <Typography variant="h4" className={classes.title}>
                                Import an Event Plan
                </Typography>
                        </Grid>
                        <Grid className={classes.grids} container
                            direction="row"
                            justify="center"
                            alignItems="center" xs={12}>
                            {/* <input type="file" id="myFile" name="file" /> */}

                            <FormControl variant="outlined">
                                <OutlinedInput
                                    id="myFile" type="file" name="file"
                                    endAdornment={<InputAdornment position="start">  <AttachFileIcon fontSize="small" /></InputAdornment>}

                                    inputProps={{
                                        'aria-label': 'Upload File',
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid container
                            direction="row"
                            justify="center"
                            alignItems="center" ex={12}>
                            <Grid item>
                                <Checkbox
                                    checked={this.state.checked}
                                    onChange={e => {
                                        this.setState({ checked: e.target.checked });
                                    }} />
                            </Grid>
                            <Grid item>
                                <Typography>Create master event group?</Typography>
                            </Grid>
                        </Grid>
                        <Grid container
                            direction="row"
                            justify="center"
                            alignItems="center" xs={12}>
                            {/* <input value="Upload" type="submit"></input> */}
                            <Button variant="outlined"  size="large" aria-label="Add Event" type="submit">
                                  Next
                            </Button>
                            <Button variant="outlined"  size="large" aria-label="Add Event" onClick={this.handleSkip}>
                                  Skip
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }
}

export default withStyles(overideStyles, { withTheme: true })(ImportPlan);
