import React, { Component } from 'react';
import AvatarUploader from 'react-avatar-uploader';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import { createMuiTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';
import axios from 'axios';
const overideStyles = theme => ({
  gridMargin: {
    margin: '25px',
   
  },

});

const StyledTypography2 = withStyles((theme) => ({
  root: {
    fontSize: '1.5em',
    color: 'darkslategray',
    fontWeight: 'normal',
    fontFamily: 'Arial, Helvetica, sans-serif',
  },
}))(Typography);
const StyledTypography1 = withStyles((theme) => ({
  root: {
    fontSize: '2em',
    color: 'black',
    fontWeight: 'normal',
    fontFamily: 'Arial, Helvetica, sans-serif',
  },
}))(Typography);

class MyProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      userName: "",
      email: "",
      phone: "",
      timeZone: "",
      aboutMe: "",
      organization: "",
      city: "",
      state: "",
      teamsRoster: "",
      userId: "",
      profileURL: ""
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  handleSubmit(evt) {
    evt.preventDefault()
  }

  async componentWillMount() {
    const currentUser = await axios.get('/api/session/');
    const id = currentUser.data.user.id;
    const url = await axios.get("/api/upload_file/" + id, {
      where: {
        objectTable: "user",
        objectId: id
      }
    });

    if(url.data.upload){
      this.setState({ userId: id, profileURL: url.data.upload[0].url });
    } else {
      this.setState({ userId: id });
    }
    
  }

  render() {
    const { classes } = this.props;

    return (

      // <div className="myProfilePage">
      //     <div className="topForm">
      //         <div className="topRow">
      //             <div className="avi">
      //                 <div className="profilePicture">Profile Picture</div>
      //                 <AvatarUploader className="avatar"
      //                 size={150}
      //                 uploadURL="http://locahost:8080"
      //                 fileType={"image/png"}/>
      //             </div>
      //             <h1 className="myProfileHeader">User Name</h1>
      //         </div>
      //         <form className="createTopForm" onSubmit={this.handleSubmit}>
      //             <input className="createInput" id="topCreateInput" placeholder="Name" value={this.state.name} type="text" name="name" onChange={this.handleChange}/>
      //             <input className="createInput" id="topCreateInput" placeholder="Username" value={this.state.userName} type="text" name="userName" onChange={this.handleChange}/>
      //             <Button type="submit" className="saveButton" id="topSave">Save</Button>
      //         </form>
      //     </div>
      //     <div className="bottomForm">
      //         <div className="contactSection">
      //             <h3 className="subHeader" id="contact">Contact</h3>
      //             <form className="createBottomForm" onSubmit={this.handleSubmit}>
      //                 <input className="createInput" id="bottomCreateInput" placeholder="Email" value={this.state.email} type="text" name="email" onChange={this.handleChange}/>
      //                 <input className="createInput" id="bottomCreateInput" placeholder="Phone" value={this.state.phone} type="text" name="phone" onChange={this.handleChange}/>
      //                 <input className="createInput" id="bottomCreateInput" placeholder="Time Zone" value={this.state.timeZone} type="text" name="timeZone" onChange={this.handleChange}/>
      //                 <input className="aboutMe" id="bottomCreateInput" placeholder="About Me" value={this.state.aboutMe} type="text" name="aboutMe" onChange={this.handleChange}/>
      //                 <div className="organizationSection">
      //                     <h3 className="subHeader" id="organization">Organization</h3>
      //                     <input className="createInput" id="bottomCreateInput" placeholder="Organization" value={this.state.organization} type="text" name="organization" onChange={this.handleChange}/>
      //                     <input className="createInput" id="bottomCreateInput" placeholder="City" value={this.state.city} type="text" name="city" onChange={this.handleChange}/>
      //                     <input className="createInput" id="bottomCreateInput" placeholder="State" value={this.state.state} type="text" name="state" onChange={this.handleChange}/>
      //                     <input className="teamsRoster" id="bottomCreateInput" placeholder="Teams/Roster" value={this.state.teamsRoster} type="text" name="teamsRoster" onChange={this.handleChange}/>
      //                     <Button type="submit" className="saveButton" id="bottomSave">Save</Button>
      //                 </div>
      //             </form>
      //         </div>
      //     </div>
      // </div>



      <div>

        <Grid container
          direction="row"
          justify="center"
          alignItems="center" xs={12}
          spacing={3}>
          <Typography variant="h4" >
            Edit User Profile:
            </Typography>
        </Grid>
        <Container style={{ width: '100%' }}> 
        
        <Grid container direction="row" justify="space-evenly" alignItems="stretch" fullWidth  xs={12} spacing={3}>

          <Grid container sm={11} md={4} spacing={3}
            direction="column"
            justify="center"
            alignItems="stretch">
           <Grid item className={classes.gridMargin}>
              <StyledTypography2 variant="h4" >
                Profile Picture:
                         </StyledTypography2>
            </Grid>
            <Grid item className={classes.gridMargin}>
              <AvatarUploader className="avatar"
                size={150}
                uploadURL={"/api/upload_file/myprofile/" + this.state.userId}
                fileType={"image/png"}
                defaultImg={this.state.profileURL} />
            </Grid>

          </Grid>
          <Grid Item sm={11} md={7} spacing={3}
           direction="column"
                justify="center"
            alignItems="stretch">
            <Grid item className={classes.gridMargin}>
              <StyledTypography2 variant="h4" >
                User Name:
                                 </StyledTypography2>
            </Grid>
            <form onSubmit={this.handleSubmit}>


              <Grid item className={classes.gridMargin}>
                <TextField
                  className="textFieldAlt2"
                  label="Name" name="name"
                  placeholder="name"
                  multiline variant="outlined"
                  defaultValue={this.state.name} onChange={this.handleChange} />
              </Grid>
              <Grid item className={classes.gridMargin}>
                <TextField
                  className="textFieldAlt2"
                  label="User Name" name="userName"
                  placeholder="User Name"
                  multiline variant="outlined"
                  defaultValue={this.state.userName} onChange={this.handleChange} />
              </Grid>

              <Grid item>
                <Button variant="outlined" size="large" aria-label="Add Event" type="submit" >
                  Submit
                                    </Button>
              </Grid>
            </form>
          </Grid>
        </Grid>
        </Container>


        <Container style={{ width: '100%' }}> 
        <form onSubmit={this.handleSubmit}>
        <Grid container direction="row" justify="space-evenly" alignItems="stretch" fullWidth xs={12} spacing={3}>
        
            {/* <Grid container sm={11} md={5}
              direction="column"
              justify="center"
              alignItems="stretch">
              <Grid item>
                <StyledTypography2 variant="h2" >
                  Contact:
               </StyledTypography2>
              </Grid>

              <Grid item  className={classes.gridMargin}>
                <TextField
                className="textFieldAlt2"
                  label="Email" name="email"
                  placeholder="Email"
                  multiline variant="outlined"
                  defaultValue={this.state.email} onChange={this.handleChange} />
              </Grid>

              <Grid item  className={classes.gridMargin}>
                <TextField
                  className="textFieldAlt2"
                  label="Phone" name="phone"
                  placeholder="Phone"
                  multiline variant="outlined"
                  defaultValue={this.state.phone} onChange={this.handleChange} />
              </Grid>

              <Grid item  className={classes.gridMargin}>
                <TextField
                className="textFieldAlt2"
                  label="Time Zone" name="timeZone"
                  placeholder="Time Zone"
                  multiline variant="outlined"
                  defaultValue={this.state.timeZone} onChange={this.handleChange} />
              </Grid>

              <Grid item  className={classes.gridMargin}>
                <TextField
                className="textFieldAlt2"
                  label="About Me" name="aboutMe"
                  placeholder="About Me"
                  multiline variant="outlined"
                  defaultValue={this.state.aboutMe} onChange={this.handleChange} />
              </Grid>
            </Grid> */}

<Grid Item sm={11} md={5} spacing={3} direction="column"
             justify="center"
              alignItems="stretch">
              <Grid item>
                <StyledTypography2 variant="h4" >
                  Contact:
                       </StyledTypography2>
              </Grid>
              <Grid item  className={classes.gridMargin}>
              <TextField
                className="textFieldAlt2"
                  label="Email" name="email"
                  placeholder="Email"
                  multiline variant="outlined"
                  defaultValue={this.state.email} onChange={this.handleChange} />
              </Grid>

              <Grid item  className={classes.gridMargin}>
              <TextField
                  className="textFieldAlt2"
                  label="Phone" name="phone"
                  placeholder="Phone"
                  multiline variant="outlined"
                  defaultValue={this.state.phone} onChange={this.handleChange} />
              </Grid>

              <Grid item  className={classes.gridMargin}>
              <TextField
                className="textFieldAlt2"
                  label="Time Zone" name="timeZone"
                  placeholder="Time Zone"
                  multiline variant="outlined"
                  defaultValue={this.state.timeZone} onChange={this.handleChange} />
              </Grid>

              <Grid item  className={classes.gridMargin}>
              <TextField
                className="textFieldAlt2"
                  label="About Me" name="aboutMe"
                  placeholder="About Me"
                  multiline variant="outlined"
                  defaultValue={this.state.aboutMe} onChange={this.handleChange} />
              </Grid>
              
            </Grid>


            <Grid Item sm={11} md={5} spacing={3} direction="column"
             justify="center"
              alignItems="stretch">
              <Grid item>
                <StyledTypography2 variant="h4" >
                  Organization:
                       </StyledTypography2>
              </Grid>
              <Grid item  className={classes.gridMargin}>
                <TextField
                  className="textFieldAlt2"
                  label="Organization" name="organization"
                  placeholder="Organization"
                  multiline variant="outlined"
                  defaultValue={this.state.organization} onChange={this.handleChange} />
              </Grid>

              <Grid item  className={classes.gridMargin}>
                <TextField
                   className="textFieldAlt2"
                  label="City" name="city"
                  placeholder="City"
                  multiline variant="outlined"
                  defaultValue={this.state.city} onChange={this.handleChange} />
              </Grid>

              <Grid item  className={classes.gridMargin}>
                <TextField
                  className="textFieldAlt2"
                  label="State" name="state"
                  placeholder="State"
                  multiline variant="outlined"
                  defaultValue={this.state.state} onChange={this.handleChange} />
              </Grid>

              <Grid item  className={classes.gridMargin}>
                <TextField
                  className="textFieldAlt2"
                  label="Teams / Roster" name="teamsRoster"
                  placeholder="Teams / Roster"
                  multiline variant="outlined"
                  defaultValue={this.state.teamsRoster} onChange={this.handleChange} />
              </Grid>
              
            </Grid>
        </Grid>
        <Grid item className={classes.gridMargin} sm={11} spacing={3} direction="row"
                justify="center"
            alignItems="center">
                <Button variant="outlined" size="large" aria-label="Add Event" type="submit" >
                  Submit
                          </Button>
              </Grid>
        </form>
        </Container>




        {/* <Container style={{ width: '100%' }}>
      <Grid container direction="row" justify="space-evenly" alignItems="stretch" fullWidth spacing={12}>
          <form className="createBottomForm" onSubmit={this.handleSubmit}>

            <Grid container sm={11} md={5}   direction="column"
             justify="space-evenly"
            alignItems="stretch" >
            <Grid item  className="gridMarginAlt">
                <StyledTypography2 variant="h2" >
                  Contact:
                                 </StyledTypography2>
              </Grid>
             
              <Grid item  className="gridMarginAlt">
                <TextField
                className="textFieldAlt2"
                  label="Email" name="email"
                  placeholder="Email"
                  multiline variant="outlined"
                  defaultValue={this.state.email} onChange={this.handleChange} />
              </Grid>

              <Grid item  className="gridMarginAlt">
                <TextField
                  className="textFieldAlt2"
                  label="Phone" name="phone"
                  placeholder="Phone"
                  multiline variant="outlined"
                  defaultValue={this.state.phone} onChange={this.handleChange} />
              </Grid>

              <Grid item  className="gridMarginAlt">
                <TextField
                className="textFieldAlt2"
                  label="Time Zone" name="timeZone"
                  placeholder="Time Zone"
                  multiline variant="outlined"
                  defaultValue={this.state.timeZone} onChange={this.handleChange} />
              </Grid>

              <Grid item  className="gridMarginAlt">
                <TextField
                className="textFieldAlt2"
                  label="About Me" name="aboutMe"
                  placeholder="About Me"
                  multiline variant="outlined"
                  defaultValue={this.state.aboutMe} onChange={this.handleChange} />
              </Grid>
            </Grid>

        
            <Grid container sm={11} md={5} 
              direction="column"
              justify="space-evenly"
               alignItems="stretch"  >
                <Grid item  className="gridMarginAlt">
                <StyledTypography2 variant="h2" >
                  Organization:
                                 </StyledTypography2>
              </Grid>
              <Grid item  className="gridMarginAlt">
                <TextField
                  className="textFieldAlt2"
                  label="Organization" name="organization"
                  placeholder="Organization"
                  multiline variant="outlined"
                  defaultValue={this.state.organization} onChange={this.handleChange} />
              </Grid>

              <Grid item  className="gridMarginAlt">
                <TextField
                   className="textFieldAlt2"
                  label="City" name="city"
                  placeholder="City"
                  multiline variant="outlined"
                  defaultValue={this.state.city} onChange={this.handleChange} />
              </Grid>

              <Grid item  className="gridMarginAlt">
                <TextField
                  className="textFieldAlt2"
                  label="State" name="state"
                  placeholder="State"
                  multiline variant="outlined"
                  defaultValue={this.state.state} onChange={this.handleChange} />
              </Grid>

              <Grid item  className="gridMarginAlt">
                <TextField
                  className="textFieldAlt2"
                  label="Teams / Roster" name="teamsRoster"
                  placeholder="Teams / Roster"
                  multiline variant="outlined"
                  defaultValue={this.state.teamsRoster} onChange={this.handleChange} />
              </Grid>
            </Grid>

            <Grid item  className="gridMarginAlt">
              <Button variant="outlined" size="large" aria-label="Add Event" type="submit" >
                Submit
                       </Button>
            </Grid>
          </form>
        </Grid> 
        </Container> */}
      </div>

    );
  }
}
export default withStyles(overideStyles, { withTheme: true })(MyProfile);
