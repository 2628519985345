import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router';
import bcrypt from 'bcryptjs';
import { TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

class Register extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            email: "",
            password: "",
            redirectToReferrer: false
        }
       //this.handleSubmit = this.handleSubmit.bind(this);
           // this.handleChange = this.handleChange.bind(this);
    }
    /*handleChange(e) {
        this.setState({[e.target.name]: e.target.value})
    }*/

    handleChange = event => {
        //e.persist();
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    
    onSubmit = () => {
        //console.log('hashPass is:', this.state.hashPass);

        const salt = bcrypt.genSaltSync(10);

        const hash = bcrypt.hashSync(this.state.password, salt);
        /* axios.interceptors.response.use((response) => {
            console.log("response received");
            return response
        }, error => {
            return Promise.reject(error);
        }); */

        axios
            .post('/api/user/',  {
                name: this.state.email,
                email: this.state.email,
                password: hash
            }/* ,
            {
                headers: { 'Content-Type': 'application/json' }
            } */)
            .then(response => {
                console.log("response good ", response.status);
                if(response.status === 200){
                    console.log("registration successfull");
                    this.props.history.push('/signin/');
                }
                else {
                    console.log("else statement");
                }
            }).catch(error => {
                console.log("response bad ",error);
                console.log("registration failed: ");
                console.log(error.response.data);
            });
    }

    render() {
        return (
            <div >
            <form className="createForm" onSubmit={this.onSubmit}>
                <Grid container spacing={3}>
                    <Grid container 
                        direction="row"
                        justify="center"
                        alignItems="center" xs={12}>
                            <Typography variant="h2" >
                                Add a New User
                            </Typography>
                    </Grid>
                    <Grid className="gridMarginAlt" container 
                            direction="row"
                            justify="center"
                            alignItems="center" xs={12}>
                                <TextField
                                    className="textFieldAlt"
                                    id="createUserName"
                                    name="name"
                                    label="Name"
                                    placeholder="Ex: John Doe"
                                    multiline 
                                    variant="outlined"
                                    onChange={this.handleChange}
                                />
                        </Grid>
                            <Grid className="gridMarginAlt" container 
                            direction="row"
                            justify="center"
                            alignItems="center" xs={12}>
                                <TextField
                                    className="textFieldAlt"
                                    id="createUserEmail"
                                    name="email"
                                    label="Email"
                                    placeholder="Ex: jdoe@oiems.com"
                                    multiline 
                                    variant="outlined"
                                    onChange={this.handleChange}
                                />
                            </Grid>
                            <Grid className="gridMarginAlt" container 
                            direction="row"
                            justify="center"
                            alignItems="center" xs={12}>
                                <TextField
                                    className="textFieldAlt"
                                    id="createUserPassword"
                                    name="password"
                                    label="Password"
                                    placeholder="Ex: password123"
                                    multiline 
                                    variant="outlined"
                                    onChange={this.handleChange}
                                />
                            </Grid>
                            <Grid container 
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <Button variant="outlined" size="large" aria-label="Add Event" type="submit" >
                                    Submit
                                </Button>
                            </Grid>
                    </Grid>
            </form>
            </div>
        )
    }
}

export default withRouter(Register);
