import React, { Component } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles, createMuiTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import ContactForm from './ContactForm';

class Contact extends Component {

    render(){
        return(
            <TableContainer component={Paper}>
                <AppBar position="static">
                    <Toolbar>
                        <Grid justify='space-between' container spacing={24}>
                            <Grid item>
                                <Typography variant="h5">
                                    Contact OIEMS
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Box component="span" m={1}>
                    <Typography variant="h5" align="center">Let's Start a Conversation</Typography>
                </Box>
                <Box component="span" m={1}>
                    <Typography variant="subtitle2" align="center">Feel free to reach out to us to ask us any questions or provide your feedback. We're always happy to hear from you!</Typography>
                </Box>
                <Grid item m zeroMinWidth className="contactBottom">
                    <Box flexGrow={1} className="contactForm">
                        <ContactForm />
                        {/* <Typography variant="subtitle1" align="left">Contact form here</Typography> */}
                    </Box>
                    <Box className="contactInfo">
                        <div className="poc">
                            <Typography variant="h6" align="right">Points of Contact</Typography>
                        </div>
                        <div className="pocTitle">
                            <Typography variant="body1" fontWeight="fontWeightMedium" align="right">OIEMS</Typography>
                        </div>
                        <div className="pocInfo">
                            <Typography variant="body2" align="right">123 Street Name, Washington, DC</Typography>
                        </div>
                        <div className="pocInfo">
                            <Typography variant="subtitle2" align="right">Email: email@oiems.com</Typography>
                        </div>
                        <div className="pocInfo">
                            <Typography variant="subtitle2" align="right">Phone: 1.877.123.4567</Typography>
                        </div>          
                    </Box>
                </Grid>
            </TableContainer>
        );
    }
}
export default Contact;