import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
//import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import CardActions from '@material-ui/core/CardActions';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { NavLink, Link } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import { Select } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import CancelIcon from '@material-ui/icons/Cancel';
import moment from 'moment';
//import EventCard from './ManageUser';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ConfirmDialog from './ConfirmDialog'
import AppBar from '@material-ui/core/AppBar';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import Toolbar from '@material-ui/core/Toolbar';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import EvidentHelper from '../scripts/evidentHelper';
import EventMap from './EventMap';

const useStyles = theme => ({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        float: 'left',
    },
    pos: {
        marginBottom: 12,
    },
    linkMargin: {
        margin: '5px',
    },
    btnStyle: {
        textDecoration: 'none',
    },
    appHeader: {
        backgroundColor: '#192d56',
      },
    mapStyle: {
        width: '50%',
        height: '540px'
    },
    appBarButton: {
        color: 'white',
        border: '1px solid white',
        margin: '5px',
      }
});

const fixDate = (oldDate) => {
    return oldDate.replace(':00.000Z', '');
}

const TabPanel = (event) => {
    const value = event.value;
    const children = event.children;
    const index = event.index;
    
    return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`full-width-tabpanel-${index}`}
          aria-labelledby={`full-width-tab-${index}`}
        >
          {value === index && ( 
            <Typography>{children}</Typography>
          )}
        </div>
      );
};

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#12779f',
        color: theme.palette.common.white,
        fontSize: '1.25em',
    },
    body: {
      
        fontSize: '1.25em',
      
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        color: 'slategray',
        '&:nth-of-type(odd)': {
            // backgroundColor: ''theme.palette.action.hover'',
            backgroundColor: '#F3F4F8',
        },
        '&:nth-of-type(even)': {
          backgroundColor: '#E6E8E9',
      },
      
      '&:hover': {
        backgroundColor: 'white',
        cursor: 'pointer',
      
        },
        '& a': {
          color: '#3d3d3d',
        },
        '&:hover a': {
          color: '#12779f',
        },
    },
}))(TableRow);

class ManageEvent extends Component {
    _eventLocation = JSON.stringify({
        name: "",
        map_region: {
            latitude: 0.0,
            longitude:0.0,
            latitudeDelta: 0.1,
            longitudeDelta: 0.1,
        },
        perimeter_polygon: [],
        points_of_interest: []
    }) ;
            
    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            name: props.match.params.name,
            description: props.match.params.description,
            type: props.match.params.type,
            start: props.match.params.start,
            end: props.match.params.end,
            status: props.match.params.status,
            roster: [],
            rows: [],
            resources: [],
            value: Number(props.match.params.tab),
            left: [],
            right: [],
            leftChecked: [],
            rightChecked: [],
            checked: [],
            checkedResources: []
        }
        this.onSubmit = this.onSubmit.bind(this);
       this.handleChange = this.handleChange.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        document.addEventListener( "eventLocationChange", this.handleLocationChange, false ) ;
    }

    onSubmit = async () => {
        const name = this.state.name;
        const type = this.state.type;
        const description = this.state.description;
        const start = this.state.start;
        const end = this.state.end;
        const location = this._eventLocation;
        const status = this.state.status;
        const roster = this.state.right;

        const params = JSON.stringify({
            name: name,
            type: type,
            description: description,
            start: start,
            end: end,
            location: location,
            status: status
        });

        await axios.put('/api/event/' + this.props.match.params.id, params, {
            headers: { 'Content-Type': 'application/json' }
        });

        let id = this.state.id;
        await axios.put('/api/event/eventroster', { roster, id });

        this.props.history.push('/eventview/' + this.state.id);
    }

    handleLocationChange(e) {
        e.stopPropagation() ;
        
        this._eventLocation = e.detail.value ;
    }
    
    handleChange = (e) => {
        e.preventDefault() ;
        
        /* var value = e.target.value ;
        
        if ( e.target.name == "location" ) {
            var location = this.state.location ;
            location.name = e.target.value ;
            value = location ;
        } */
        
        this.setState({[ e.target.name ]: e.target.value })
    }

    handleTabChange = (id) => {
        this.setState({ value: id });
    } 

    async componentDidMount () {
        const id = this.props.match.params.id;
        const name = this.props.match.params.name || '';
        const description = this.props.match.params.description || '';
        const type = this.props.match.params.type || '';
        const start = moment(this.props.match.params.start).format('YYYY-MM-DDTHH:mm') || '';
        const end = moment(this.props.match.params.end).format('YYYY-MM-DDTHH:mm') || '';
        const location = JSON.parse( this.props.match.params.location ) || JSON.stringify({
            name: "",
            map_region: {
              lat: 38.889260228566236,
              lng:-77.0501760013805,
              deltaLat: 0.003,
              deltaLng: 0.004,
            },
            perimeter_polygon: [],
            points_of_interest: []
        }) ;
        const status = this.props.match.params.status || '';
        const tabValue = this.props.match.params.tab || 0;
        
        await axios.get('/api/roster/')
            .then(res => {
                let roster = res.data.user || [];
                this.setState({ rows: roster });
            })
            .catch(error => {
                console.log(error);
            });

        this.setState({ id: id, name: name, type: type, description: description, start: start, end: end, status: status });
        this._eventLocation = location ;
        
        await axios.get('/api/event/getroster/' + id)
            .then(res => {
                let roster = [];
                for(const user of res.data.roster){
                    roster.push(user);
                }
                
                this.setState({ roster: roster });
            });
        await axios.get('/api/upload_file/' + id)
        .then(res => {
            const data = res.data.upload || [];
            this.setState({ resources: data });
            if(data){
                data.forEach(item => {
                    let checkedItems = this.state.checked.concat(false);
                    this.setState({ checkedResources: checkedItems });
                });
            }
        });
        await this.setLists();
    }

    setLists = () => {
        let fullRoster = [];
        for(const user of this.state.rows){
            fullRoster.push(user.firstName + ' ' + user.lastName);
        }
        console.log(fullRoster);
        //let fullRoster = this.state.rows;
        let rightSide = this.state.roster;
        let leftSide = fullRoster.filter((value) => !rightSide[rightSide.indexOf(value)]);
        let rightChecked = this.state.checked.filter((item) => rightSide.includes(item));
        let leftChecked = this.state.checked.filter((item) => leftSide.includes(item));
        this.setState({ left: leftSide });
        this.setState({ right: rightSide });
        this.setState({ rightChecked: rightChecked });
        this.setState({ leftChecked: leftChecked });
    }

    /* shouldComponentUpdate = ( newProps, nextState ) => {
      var eventId = parseInt( newProps.eventId ) ;
      return (( newProps.match.params.id != this.props.match.params.id ) && ( eventId != NaN ) && ( eventId > 0 )) ;
    } */

    render() {
        const { classes } = this.props;

        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: 180,
                    width: 150,
                },
            },
        };


        const handleToggle = (value) => {
            const currentIndex = this.state.checked.indexOf(value);
            const newChecked = this.state.checked;
            if(currentIndex === -1){
                newChecked.push(value);
            } else {
                newChecked.splice(currentIndex, 1);
            }
            this.setState({ checked: newChecked });
            this.setLists();
        };

        const handleAllRight = () => {
            let right = this.state.right.concat(this.state.left);
            this.setState({ right: right });
            this.setState({ left: [] });
        };

        const handleAllLeft = () => {
            let left = this.state.left.concat(this.state.right);
            this.setState({ left: left });
            this.setState({ right: [] });
        };

        const handleCheckedRight = () => {
            let checked = this.state.right.concat(this.state.leftChecked);
            let left = this.state.left;
            let toRemove = this.state.leftChecked;
            this.setState({ right: checked });
            let newLeft = left.filter((item) => left[left.indexOf(item)] !== toRemove[toRemove.indexOf(item)]);
            this.setState({ left: newLeft });
        };

        const handleCheckedLeft = () => {
            let checked = this.state.left.concat(this.state.rightChecked);
            let right = this.state.right;
            let toRemove = this.state.rightChecked;
            this.setState({ left: checked });
            let newRight = right.filter((item) => right[right.indexOf(item)] !== toRemove[toRemove.indexOf(item)]);
            this.setState({ right: newRight });
        };

        const customList = (items) => (
            <Paper>
                
                <List dense component="div" role="list" style={{ maxHeight: 400, height: 400, overflow: 'auto' }}>
                    {items.map((value) => {
                        const labelId = `transfer-list-item-${value}-label`;

                        return(

                            <ListItem key={value} role="listitem" button onClick={() => {handleToggle(value)} }>
                                <ListItemIcon>
                                    <Checkbox
                                        checked={this.state.checked.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId}}
                                        />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={value} />
                            </ListItem>
                        );
                    })}
                </List>
            </Paper>
        );

        const ResourceTable = () => {
            
            return (
                <TableContainer component={Paper}>
                    <AppBar position="static" className={classes.appHeader}>
                        <Toolbar>
                            <Grid justify='space-between' container spacing={24}>
                                <Grid item>
                                    <Typography variant="h4" className={classes.title}>
                                        List of Resources
                                    </Typography>
                                </Grid>
                                <Grid container direction="row" justify="flex-end" alignItems="center" xs={8}>
                                    {/* <div className={classes.search}>
                                    <div className={classes.searchIcon}>
                                        <SearchIcon />
                                    </div>
                                    <InputBase 
                                        placeholder="Search.."
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput,
                                        }}
                                        inputProps={{ 'aria-label': 'search' }}
                                        />
                                    </div> */}
                                    <Button 
                                        variant="outlined" 
                                        className={classes.appBarButton} 
                                        onClick={ () => this.setConfirmOpen( true )}>
                                        <DeleteForeverOutlinedIcon fontSize="large" /> Delete
                                    </Button>
                                    <ConfirmDialog
                                        title="Delete User?"
                                        open={ this.state.confirmOpen }
                                        setOpen={ this.setConfirmOpen }
                                        onConfirm={ this.onDelete } >
                                        Delete?
                                    </ConfirmDialog>
                                    <NavLink to={"/importresource/" + this.state.id}><Button variant="outlined" aria-label="Add Resource" className={classes.appBarButton}>
                                        <AddBoxOutlinedIcon fontSize="large"/> Add Resource
                                    </Button></NavLink>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <Table className={classes.table} aria-label="resource list table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Select</StyledTableCell>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell>Size</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.resources && this.state.resources.map((row, index) => {
                                let isChecked = this.state.checkedResources[index];

                                return (
                                    <StyledTableRow key={row.name}>
                                        <TableCell>
                                            <Checkbox
                                                key={index}
                                                checked={isChecked}
                                                onChange={e => this.checkboxChanged(row.id, index, e.target.checked)}/>
                                        </TableCell>
                                        <StyledTableCell>
                                           {row.name}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {row.size}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }

        return (
            <Card className={classes.root} variant="outlined">
                <CardContent justify='center' justify="center"
                    alignItems="center" xs={12}>

                    <form>
                        <Grid container
                                    direction="row"
                                    justify="center"
                                    alignItems="center" xs={12}>
                                    <Typography variant="h4" >
                                        Edit Event Details
                                    </Typography>
                                </Grid>
                        <Grid>
                            <Tabs value={this.state.value} aria-label="change component">
                                <Tab label="Event Details" id='0' onClick={()=>this.handleTabChange(0)}/>
                                <Tab label="Event Roster" id='1' onClick={()=>this.handleTabChange(1)}/>
                                <Tab label="Event Map" id='2' onClick={()=>this.handleTabChange(2)}/>
                                <Tab label="Resources" id='3' onClick={()=>this.handleTabChange(3)}/>
                            </Tabs>
                            <TabPanel value={this.state.value} index={0}>

                                <Grid className="gridMarginAlt" container
                                    direction="row"
                                    justify="center"
                                    alignItems="center" xs={12}>
                                    <TextField className="textFieldAlt" label="Event Name" variant="outlined" name="name"
                                        defaultValue={this.state.name} InputLabelProps={{ shrink: true }} onChange={this.handleChange}/* onInput={this.handleChange} */ />

                                </Grid>

                                <Grid className="gridMarginAlt" container
                                    direction="row"
                                    justify="center"
                                    alignItems="center" xs={12}>
                                    <TextField className="textFieldAlt" label="Description" variant="outlined" name="description"
                                        defaultValue={this.state.description}
                                        multiline
                                        rows={4}
                                        InputLabelProps={{ shrink: true }} 
                                        onChange={this.handleChange}/>

                                </Grid>

                                <Grid className="gridMarginAlt" container
                                    direction="row"
                                    justify="center"
                                    alignItems="center" xs={12}>
                                    <TextField className="textFieldAlt" label="Type" variant="outlined" name="type"
                                        defaultValue={this.state.type}
                                        InputLabelProps={{ shrink: true }} 
                                        onChange={this.handleChange}/>

                                </Grid>


                                <Grid className="gridMarginAlt" container
                                    direction="row"
                                    justify="center"
                                    alignItems="center" xs={12}>
                                    <TextField className="textFieldAlt" label="Start time" type="datetime-local" variant="outlined" name="start"
                                        defaultValue={fixDate(this.state.start)}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={this.handleChange}  />

                                </Grid>


                                <Grid className="gridMarginAlt" container
                                    direction="row"
                                    justify="center"
                                    alignItems="center" xs={12}>
                                    <TextField className="textFieldAlt" label="End time" type="datetime-local" variant="outlined" name="end"
                                        defaultValue={fixDate(this.state.end)}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={this.handleChange}  />

                                </Grid>


                                <Grid className="gridMarginAlt" container
                                    direction="row"
                                    justify="center"
                                    alignItems="center" xs={12}>
                                    <TextField className="textFieldAlt" label="Status" variant="outlined" name="status"
                                        defaultValue={this.state.status}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={e => {
                                            this.setState({ status: e.target.value })
                                        }}
                                        select>
                                            <MenuItem value="Pre Planning">Pre Planning</MenuItem>
                                            <MenuItem value="Planning">Planning</MenuItem>
                                            <MenuItem value="In Progress">In Progress</MenuItem>
                                            <MenuItem value="Complete">Complete</MenuItem>
                                            <MenuItem value="Pending">Pending</MenuItem>
                                            <MenuItem value="Active">Active</MenuItem>
                                            <MenuItem value="Ready">Ready</MenuItem>
                                    </TextField>

                                </Grid>
                            </TabPanel>
                            <TabPanel value={this.state.value} index={1}>
                                {/* <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                    <InputLabel htmlFor="outlined-roster">Event Roster</InputLabel>
                                </Grid> */}
                                <Grid container spacing={2} justify="center" alightItems="space-around">
                                    <Grid item style={{width: '35%'}}>
                                        <InputLabel htmlFor="outlined-roster">Available Roster</InputLabel>
                                        {customList(this.state.left)}
                                        </Grid>
                                    <Grid item>
                                        <Grid container direction="column" alignItems="center">
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={handleAllRight}
                                                
                                                disabled={this.state.left.length === 0}
                                                aria-label="move all right"
                                            >
                                                {'>>'}
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                
                                                onClick={handleCheckedRight}
                                                disabled={this.state.leftChecked.length === 0}
                                                aria-label="move selected right"
                                            >
                                                &gt;
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            
                                            onClick={handleCheckedLeft}
                                            disabled={this.state.rightChecked.length === 0}
                                            aria-label="move selected left"
                                        >
                                            &lt;
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            
                                            onClick={handleAllLeft}
                                            disabled={this.state.right.length === 0}
                                            aria-label="move all left"
                                        >
                                            ≪
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item style={{width: '35%'}}>
                                <InputLabel htmlFor="outlined-roster">Event Roster</InputLabel>
                                    {customList(this.state.right)}
                                </Grid>
                            </Grid>
                            </TabPanel>
                            <TabPanel value={this.state.value} index={2}>
                                <Grid className="gridMarginAlt" container
                                    direction="row"
                                    justify="center"
                                    alignItems="center" xs={12}
                                    style={useStyles.mapStyle}>
                                    <EventMap eventId={this.state.id} readOnly={false} />
                                </Grid>
                            </TabPanel>
                            <TabPanel value={this.state.value} index={3}>
                                <Grid className="gridMarginAlt" container
                                    direction="row"
                                    justifty="center"
                                    alignItems="center" xs={12}>
                                    {/* <ImportResource /> */}
                                    <ResourceTable />
                                </Grid>
                            </TabPanel>
                            <CardActions>
                                <Grid className="gridMarginAlt" container
                                spacing={2}
                                    direction="row"
                                    justify="center"
                                    alignItems="center" xs={12}>
                                        <Grid item>
                                    <NavLink  style={{ textDecoration: 'none' }} to={'/eventview/' + this.state.id + '/' + this.state.value} >
                                        <Button color="secondary" variant="outlined" size="large">Cancel </Button>
                                    </NavLink>
                                    </Grid>
                                    <Grid item>
                                    {/* <NavLink style={{ textDecoration: 'none' }} to={'/eventview/' + this.state.id} > */}
                                        <Button  color="primary" variant="outlined" size="large" onClick={this.onSubmit}>Save </Button>
                                    {/* </NavLink> */}
                                    </Grid>
                                    {/* <Grid item>
                                        <Link to="/importresource" style={{ textDecoration: 'none' }}>
                                            <Button variant="outlined" size="large" aria-label="Add Resource" type="submit" >
                                                Upload Resource
                                            </Button>
                                        </Link>
                                    </Grid> */}
                                </Grid>
                            </CardActions>
                        </Grid>
                    </form>
                </CardContent>


            </Card>





        )
    }
}
export default withStyles(useStyles, { withTheme: true })(ManageEvent);
