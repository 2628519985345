import React from 'react';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

import '../App.css';
import axios from 'axios';
import { withRouter } from 'react-router';
import { useHistory, Link } from 'react-router-dom';

import oiems_logo from '../media/oiems_logo.png' ;

const theme = createMuiTheme({

});

const overideStyles = makeStyles({
    headerButton: {
        height: '100%',
        padding: '10px',
        marginTop: '0.5em',
        borderLeft: '2px solid lightgray',
        color: '#022958',
        float: 'right',
        fontSize: '1.5em',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
          },
    },
    headerButton2: {
        height: '100%',
        padding: '10px',
        marginTop: '0.5em',
        borderLeft: '2px solid lightgray',
        color: 'darkslategray',
        float: 'right',
        fontSize: '1.5em',
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
          },
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    oiemsLogo: {
        width: 'auto',
        height: '4.5em',
    }
});



function Appbar() {

    const classes = overideStyles();
    const [state, setState] = React.useState({
        right: false,
    });
    const history = useHistory();

    const handleClick = () => {

        axios.get('/api/logout/')
        .then(res => {
            console.log("user is: ", res.data.user);
            if(res.data.user){
                console.log(res.data.message);
                history.push('/logout');
                window.location.reload(false);
            } else {
                console.log(res.data.message);
            }
        })
        .catch(err => {
            console.log(err);
        });
        history.push('/logout');
        window.location.reload(false);
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };


    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {['About', 'Help', 'My Profile', 'Logout'].map((text, index) => (
                    <ListItem button key={text}>
                        <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {['Home', 'Events', 'Import Event Plan', 'Archived Event','My Users', 'Sync User', 'My Teams'].map((text, index) => (
                    <ListItem button key={text}>
                        <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List>
        </div>
    );




    return (
        <div className="header">
            <div className="header-Logo">
                <img src={oiems_logo} classes={classes.oiemsLogo} style={{ width: 'auto', height: '4.5em' }} alt="this is the oiems logo image" />
            </div>


           

            <Button className="header-menuButton">Menu</Button>
            {/* <Button  >MENU</Button> */}
            {[ 'right',].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button className={classes.headerButton2} onClick={toggleDrawer(anchor, true)}>MENU</Button>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}

            <Button className={classes.headerButton} onClick={handleClick}>Logout</Button>
            <Button className={classes.headerButton} component={Link} to="/myprofile">
                    My Profile
                </Button>
            <Button className={classes.headerButton} component={Link} to="/help">Help</Button>
            <Button className={classes.headerButton} component={Link} to="/about">About</Button>


        </div>
    )
}

export default withRouter(Appbar);
