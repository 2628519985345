import React from 'react';
import { useForm, ValidationError } from '@formspree/react';

function DemoForm() {
    const [state, handleSubmit] = useForm("myylgdkb");
    if (state.succeeded) {
        return <p>We'll get back to you as soon as possible!</p>;
    }
    return (
        <form onSubmit={handleSubmit}>
            <div className="demoField">
                <label htmlFor="email">
                    Email Address
                </label>
                <input className="demoInput"
                    id="email"
                    type="email"
                    name="email"
                />
                <ValidationError
                    prefix="Email"
                    field="email"
                    errors={state.errors}
                />
            </div>
            <div className="demoField">
                <label htmlFor="fullName">
                    Full Name
                </label>
                <input className="demoInput"
                    id="fullName"
                    type="fullName"
                    name=" fullName"
                />
            </div>
            <div className="demoField">
                <label htmlFor="companyName">
                    Company Name
                </label>
                <input className="demoInput"
                    id="companyName"
                    type="companyName"
                    name="companyName"
                />
            </div>
            <div className="demoField">
                <label htmlFor="phoneNumber">
                    Phone Number
                </label>
                <input className="demoInput"
                    id="phoneNumber"
                    type="phoneNumber"
                    name="phoneNumber"
                />
            </div>
            <button type="submit" disabled={state.submitting}>
                Submit
            </button>
        </form>
    );
}

function App() {
    return (
        <DemoForm />
    );
}
export default App;
