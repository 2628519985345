// import logo from './logo.svg';
import React, { Component } from 'react';
import './App.css';
import { Route, Switch, Redirect } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Appbar from './components/Appbar';
import Events from './components/Events';
import UserList from './components/UserList';
import UserView from './components/UserView';
import EventView from './components/EventView';
import CreateEvent from './components/CreateEvent';
import ImportPlan from './components/ImportPlan'; 
import ImportUsers from './components/ImportUsers';
import CreateUser from './components/CreateUser'; 
import ManageUser from './components/ManageUser';
import ManageEvent from './components/ManageEvent';
import AddUser from './components/AddUser'; 
import ArchivedEvents from './components/ArchivedEvents';
import EventHub from './components/EventHub';
import CreateGroup from './components/CreateGroup';
import CreateImportGroup from './components/CreateImportGroup';
import Groups from './components/Groups';
import SignIn from './components/SignIn';
import home from './components/home';
import Logout from './components/logout';
import register from './components/register';
import CreateEventMap from './components/CreateEventMap';
import axios from 'axios';
import { jssPreset } from '@material-ui/core';
import MyProfile from './components/MyProfile';
import About from './components/About';
import Help from './components/Help';
import Contact from './components/Contact';
import RequestDemo from './components/RequestDemo';
import ImportResource from './components/ImportResource';

class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      isLoggedIn: false,
      redirect: '/signin/',
      comp: SignIn,
      token: '',
    }
  }

  componentDidMount() {
    console.log('Mounting app');
    axios.get('/api/session/')
      .then(res => {
        if(res.status === 200){
          const auth = res.data.data;
          if(auth){
            this.setState({ isLoggedIn: auth});
            this.setState({ redirect: '/' });
            this.setState({ comp: home });
            this.setState({ token: res.data.user });
          }
        }
      });
  }

  componentDidUpdate(){
    axios.get('/api/session/')
      .then(res => {
        if(res.status !== 200){
          this.setState({ isLoggedIn: false});
        }
  });
}


  render(){

    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route {...rest} render={(props) => (
        this.state.isLoggedIn
          ? <Component {...props} />
          : <Redirect to={this.state.redirect} component={this.state.home} />
      )} />
    );


    return (
      <div>
        <Appbar />
        <Sidebar />
        <div className='main'>
        <Switch>
            <Route exact path='/' component={home} />
            <PrivateRoute path='/events' component={Events}/>
            <PrivateRoute path='/userlist' component={UserList}/>
            <PrivateRoute path='/createevent' component={CreateEvent}/>
            <PrivateRoute path='/import/:id/:name' component={ImportPlan}/>
            <PrivateRoute path='/importusers' component={ImportUsers}/>
            <PrivateRoute path='/importresource/:id' component={ImportResource}/>
            <PrivateRoute path='/eventview/:id/:tab?' component={EventView}/>
            <PrivateRoute path='/eventhub' component={EventHub}/>
            {/*<Route path='eventhub' component={EventHub}/>*/}
            <PrivateRoute path='/manageuser/:id/:firstName/:middleInitial?/:lastName?/:email?/:supervisor?/:role?/:organization?' component={ManageUser}/>
            <PrivateRoute path='/manageevent/:id/:tab/:name/:description?/:type?/:start?/:end?/:location?/:status?' component={ManageEvent}/>
            <PrivateRoute path='/createuser' component={CreateUser}/>
            <PrivateRoute path='/createeventmap/:id/:name' component={CreateEventMap} />
            <PrivateRoute path='/adduser' component={AddUser}/>
            <PrivateRoute path='/userview/:id' component={UserView}/>
            <PrivateRoute path='/archivedevent' component={ArchivedEvents}/>
            <PrivateRoute path='/creategroup' component={CreateGroup} />
            <PrivateRoute path='/myprofile' component={MyProfile}/>
            <PrivateRoute path='/about' component={About}/>
            <PrivateRoute path='/help' component={Help}/>
            <PrivateRoute path='/contactus' component={Contact}/>
            <PrivateRoute path='/createimportgroup' component={CreateImportGroup}/>
            <PrivateRoute path='/groups' component={Groups} />
            <PrivateRoute path='/requestdemo' component={RequestDemo}/>
            <Route path='/signin/' component={SignIn}/>
            <Route path='/register/' component={register}/>
            <Route path='/logout' component={Logout}  />
        </Switch>
        </div>
      </div>
    );
  }
};

export default App;
