const EvidentHelper = {

  mapStatus: (status) => {
    switch (status) {
      case "shared":
        return "Complete";
      case "pending":
        return "Pending Verification";
      case "timeout":
        return "Request expired";
      case "unfulfillable":
        return "Cannot be completed";
      case "error":
        return "Error";
      case "rejected":
        return "Request rejected";
      case "unavailable":
        return "Request Unavailable";
      case "sent":
        return "Request submitted";
      default:
        return "Not Verified";
    }
  },
}

export default EvidentHelper