import React, { Component } from 'react';
import { fade, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ConfirmDialog from './ConfirmDialog'
import { NavLink } from 'react-router-dom';


const overideStyles = theme => ({
    table: {
      minWidth: 650,
    },
    toolbarButtons: {
      marginRight: 'auto',
    },
    appHeader: {
      backgroundColor: '#192d56',
    },
    appBarButton: {
      color: 'white',
      border: '1px solid white',
      margin: '5px',
    },
    title: {
      float: 'left',
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
   
      marginRight: theme.spacing(2),
      marginLeft: 0,
   
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  });

  const StyledTableCell = withStyles((theme) => ({
      head: {
          backgroundColor: '#12779f',
          color: theme.palette.common.white,
          fontSize: '1.25em',
      },
      body: {
          // bodySize: 14,
          padding: 5,
        
      },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
      root: {
          '&:nth-of-type(odd)': {
              // backgroundColor: ''theme.palette.action.hover'',
              backgroundColor: '#F3F4F8',
          },
          '&:nth-of-type(even)': {
            backgroundColor: '#E6E8E9',
        },
      },
  }))(TableRow);

class EventTable extends Component {

  constructor(props){
    super(props)
    this.state = {
      id: '',
      confirmOpen: false,
      rows: []
    }
  }
   
  componentDidMount() {
    axios.get('/api/archived')
      .then(res => {
        const data = res.data.event;
          this.setState({ rows: data });
      });
  }

  handleChange = event => {
    event.preventDefault();

    if(this.confirmOpen === false){
      this.setState({ confirmOpen: true });
    }
    else{
      this.setState({ confirmOpen: false});
    }       
  }

  handleSubmit = event => {
    event.preventDefault();

  axios.delete('/api/event'+event.id)
    .then(res => {
      console.log(res);
      console.log(res.data);
    })
  }

  render(){
    const { classes } = this.props;

    return (
      <TableContainer component={Paper}>
        <AppBar position="static" className={classes.appHeader}>
        <Toolbar> 
          <Grid justify='space-between' container spacing={24}>
            <Grid item>
              <Typography variant="h4" className={classes.title}>
                List of Events
              </Typography>
            </Grid>
            <Grid container direction="row" justify="flex-end" alignItems="center" xs={6}>
            <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
                <Button variant="outlined" className={classes.appBarButton} onClick={() => this.setConfirmOpen(true)} >
                  <DeleteForeverOutlinedIcon fontSize="large" /> Delete
                </Button>
                <Button variant="outlined" aria-label="Add Event"  className={classes.appBarButton}>
                <AddBoxOutlinedIcon fontSize="large"/>  Add Event
                  </Button>
                <ConfirmDialog
                    title="Delete Event?"
                    open={this.confirmOpen}
                    setOpen={this.setConfirmOpen}
                    onConfirm={null}
                  >
                    Are you sure you want to delete this event?
                  </ConfirmDialog>
            </Grid>
          </Grid>
        </Toolbar>
        </AppBar>
        <Table className={classes.table} aria-label="event list table">
          <TableHead>
            <TableRow>
                    <StyledTableCell>Select</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Code</StyledTableCell>
                    <StyledTableCell>Date</StyledTableCell>
                    <StyledTableCell>Location</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.rows && this.state.rows.map((row) => (
              <StyledTableRow key={row.name}>
                <TableCell>
                  <Checkbox />
                </TableCell>
                <StyledTableCell component="th" scope="row"><NavLink to="/eventview" activeClassName="EventViewSelected">
                  {row.name}
                </NavLink></StyledTableCell>
                <StyledTableCell>{row.code}</StyledTableCell>
                <StyledTableCell>{row.start}</StyledTableCell>
                <StyledTableCell>{row.location}</StyledTableCell>
                <StyledTableCell>{row.status}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
export default withStyles(overideStyles, { withTheme: true })(EventTable);
