import React, { Component } from 'react';
//import SignIn from './SignIn';
//import App from '../App';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
//import myCal from './homePage/myCalendar';
import { Container, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';

import Paper from '@material-ui/core/Paper';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { getFormattedDateTime } from '../FormatDate';
import { NavLink } from 'react-router-dom';

/*const theme = createMuiTheme({
    palette: {
      primary: {
       main: purple[500],
       light: purple[500],
       dark: purple[500],
      },
    },
  });

const useStyles = theme => ({
    root: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        border: 2,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: grey,
    },
    h2: {
        font: 'Helvetica',
        textTransform: 'uppercase',
        color: blueGrey,
    }
});*/
const overideStyles = theme => ({
    mainHome: {
        paddingBottom: '20px',
        borderBottom: '5px solid #192d56',
        marginBottom: '25px',
    },
    infoSections: {
        borderLeft: '2px solid #12779f', 
        marginTop: '25px',
    },
    tableMaxHeight: {
        maxHeight: '100%',
        overflowY: 'scroll',
    },
   h4Font: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
   },
   Active: {
    backgroundColor: '#6af072'
    },
    Pending: {
        backgroundColor: '#f7f136'
    },
    Planning: {
        backgroundColor: '#c9c9c5'
    },
    Complete: {
        backgroundColor: '#02759a'
    }
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#192d56',
        color: theme.palette.common.white,
        fontSize: '1em',
    },
    body: {
        fontSize: '1em',
    },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
    root: {
        color: '#3d3d3d',
        '&:nth-of-type(odd)': {
            // backgroundColor: ''theme.palette.action.hover'',
            backgroundColor: '#F3F4F8',
        },
        '&:nth-of-type(even)': {
          backgroundColor: '#E6E8E9',
      },
      '&:hover': {
      backgroundColor: 'white',
      cursor: 'pointer',
      color: '#12779f',
    
      },
      '& a': {
        color: '#3d3d3d',
      },
      '&:hover a': {
        color: '#12779f',
      },
    },
}))(TableRow);
const StyledTypography2 = withStyles((theme) => ({
    root: {
        fontSize: '1.5em',
        color: 'darkslategray',
        fontWeight: 'normal',
        fontFamily: 'Arial, Helvetica, sans-serif',
    },
}))(Typography);
const StyledTypography1 = withStyles((theme) => ({
    root: {
        fontSize: '1.5em',
        color: 'black',
        fontWeight: 'normal',
        fontFamily: 'Arial, Helvetica, sans-serif',
    },
}))(Typography);

class home extends Component {
    _timerId = 0 ;
    
    constructor(props){
        super(props);
        this.state = {
          isLoggedIn: false,
          events: [],
          nextEvent: '',
          rows: [],
          date: new Date(),
          time: new Date(),
        }
        
        this.getEventProps = this.getEventProps.bind( this ) ;
      }

    componentDidMount() {
        console.log("Mounting Home page");
        axios.get('/api/session/')
          .then(res => {
            if(res.status === 200){
              //console.log("response: ", res.data)
              const auth = res.data.data;
              //console.log(auth);
              if(auth){
                this.setState({ isLoggedIn: auth});
                //this.setState({ redirect: '/' });
              }
          }
          });
          
        axios.get('/api/event')
            .then(res => {
            const data = res.data.event;
            this.setState({ rows: data });
            let temp = [];

            if(data.length > 0){
                data.forEach(element => {
                    temp.push(
                        {
                            start: new Date(element.start),
                            end: new Date(element.end),
                            title: element.name,
                            status: element.status
                        }
                    ); 
                })
            
        }
            
        this.setState({ events: temp });
        });

        axios.get('/api/event/nextEvent/')
            .then(res => {
                if(res.data.nextEvent){
                    const event = res.data.nextEvent;
                this.setState({ nextEvent: event });
                }
            });
            
            this._timerId = setInterval(() => this.setState({ time: new Date() }), 5000 ) ;
      }

      componentWilUnmount() {
          clearInterval( this._timerId ) ;
      }

      isEventActive = ( event ) => {
          if ( event === null ) return false ;
          
          var currentTime = new Date() ;
          var startTime   = new Date( event.start ) ;
          var endTime     = new Date( event.end ) ;
          
          return (( startTime <= currentTime ) && ( currentTime < endTime )) ;
      }
      
      getEventProps( event, start, end, isSelected ) {
          if(this.isEventActive( event )){
            var eventBackgroundColor = '#6af072';
          }
          else if(event.status === 'Pre Planning' || event.stuats === 'Planning'){
            var eventBackgroundColor = '#c9c9c5';
          }
          else if(event.status === 'Ready' || event.status === 'Pending'){
            var eventBackgroundColor = '#f7f136';
          }
          else if(event.status === 'Complete'){
            var eventBackgroundColor = '#eb4034';
          }
          else {
            var eventBackgroundColor = '#02759a';
          }
          //var eventBackgroundColor = ( this.isEventActive( event )) ? '#6af072' : '#02759a' ;
          
          return ({ style: { backgroundColor: eventBackgroundColor }}) ;
      }
      
    render(){
        let homePage;
        const { classes } = this.props;

        let allViews = Object.keys(Views).map(k => Views[k]);
        
        const localizer = momentLocalizer(moment);

        if(this.state.isLoggedIn){
            homePage =  
                <div>
                    <body>
                        <Container style={{ width: '100%' }}>
                            <Grid container direction="column" justify="flex-start" alignItems="center" fullWidth spacing={6}>
                                <Grid item style={{ height: 600, width: '100%', marginBottom:'40px' }}>
                                    <StyledTypography2 variant="h4" >
                                        Event Calendar
                                    </StyledTypography2>
                                    <h2 justify='center'></h2>
                                    <div style={{ height: '100%', width: '100%' }} >
                                        <Calendar
                                            localizer={localizer}
                                            style={{ height: '400' }}
                                            events={this.state.events}
                                            defaultView="month"
                                            step={60}
                                            views={allViews}
                                            onView={() => { }}
                                            date={this.state.date}
                                            onNavigate={date => this.setState({ date })}
                                            eventPropGetter={this.getEventProps}
                                        />
                                    </div>
                                </Grid>

                                <Grid item style={{ height: 600, width: '100%' }}>
                                    <StyledTypography2 variant="h4" >
                                        Event List
                                    </StyledTypography2>
                                    <TableContainer component={Paper}  className={classes.tableMaxHeight}>
                                        <Table stickyHeader >
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell>Name</StyledTableCell>
                                                    <StyledTableCell>Code</StyledTableCell>
                                                    <StyledTableCell>
                                                        <span>Starts</span>
                                                        <br />
                                                        <span>Ends</span>
                                                    </StyledTableCell>
                                                    <StyledTableCell>Location</StyledTableCell>
                                                    <StyledTableCell>Status</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.rows && this.state.rows.map((row) => {
                                                    var locationName       = ( row.location ) ? JSON.parse( row.location ).name : "" ;
                                                    var highlightRowStyle = {} ;
                                                    
                                                    if ( this.isEventActive( row )) {
                                                        highlightRowStyle = { backgroundColor: '#6af072' } ;
                                                    }
                                                    
                                                    return (
                                                        <StyledTableRow key={row.name} style={highlightRowStyle}>
                                                            <StyledTableCell>
                                                                <NavLink to={"/eventview/" + row.id} activeClassName="EventViewSelected">
                                                                    {row.name}
                                                                </NavLink>
                                                            </StyledTableCell>
                                                            <StyledTableCell>{row.code}</StyledTableCell>
                                                            <StyledTableCell>
                                                                    <span>{getFormattedDateTime(row.start)}</span>
                                                                    <br />
                                                                    <span>{getFormattedDateTime(row.end)}</span>
                                                            </StyledTableCell>
                                                            <StyledTableCell>{locationName}</StyledTableCell>
                                                            <StyledTableCell>{row.status}</StyledTableCell>
                                                        </StyledTableRow>) ;
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Container>
                    </body>
                </div>;
        }
        else {
            homePage = <h1><a href="/signin">Please Sign in</a></h1>;
        }

        return(
            <div>
                {homePage}
            </div>
            
        );
    }
}
export default withStyles(overideStyles, { withTheme: true }) (home);
