import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import Tabs from '@material-ui/core/Tabs';
import TabPanel from '@material-ui/core/Tabs';
import Chat from './Chat';
import Tab from '@material-ui/core/Tab';
import EventMap from './EventMap';
import Weather from './Weather';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Button from '@material-ui/core/Button';

import image1 from './../media/mallmap.png';
import image2 from './../media/mallmap2.png';
import image3 from './../media/eventcontactlist.png';
//import image4 from './../media/capitalonearena2.jpg';
import thumb1 from './../media/thumb1.png';
import thumb2 from './../media/thumb2.png';
//import thumb4 from './../media/thumb4.jpg';

 

const useStyles = theme => ({
    table: {
        minWidth: 650,
    },
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        minWidth: 650,
    }, 
    imagelist: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden'
    }
});

const EventHubTabPanel = ( props ) => {
    const { value, children, index } = props ;
    
    return (
        <div
          role="tabpanel"
          hidden={ value !== index }
          id={ `full-width-tabpanel-${ index }` }
          aria-labelledby={ `full-width-tab-${ index }` }
        >
          { value === index && ( 
            <Typography style={{ width: '100%' }}>{ children }</Typography>
          )}
        </div>
      ) ;
} ;

class EventHubTabView extends Component {
    constructor( props ) {
        super( props ) ;
        this.state = {
            eventId: 0,
            location: { lat: NaN, lon: NaN },
            start: 0,
            end: 0,
            resources: [],
            tabValue: 0,
            children: '',
            fullScreen: false,
        } ;
        
        this.handleTabChange = this.handleTabChange.bind( this ) ;
        this.getCol          = this.getCol.bind( this ) ;
    }

    handleTabChange = ( event, newValue ) => {
        this.setState({ tabValue: newValue }) ;
    }

    toggleScreen = ( img ) =>{
        let toggle = !this.state.fullScreen ;
        if( img ) {
            this.setState({ fullScreen: toggle, image: img.url }) ;
        } else {
            this.setState({ fullScreen: toggle }); 
        }      
    }

    getCol = ( index ) => {
        let col = ( index % 2 ) + 1;
        console.log( "column: ", col ) ;
        return col ;
    }

    getEventData( eventId ) {
        if ( !eventId ) return ;
        
        let data, files, location, lat, lng, start, end ;
        
        let p1 = axios.get( '/api/event/' + eventId )
        .then( res => {
            data     = res.data.event;
            location = JSON.parse( data.location ) ;
            start    = data.start && moment( data.start ).format( 'HH:mm' ) ;
            end      = data.end && moment( data.end ).format( 'HH:mm' ) ;
            lat      = ( location.map_region ) ? location.map_region.lat : NaN ;
            lng      = ( location.map_region ) ? location.map_region.lng : NaN ;
        });

        let p2 = axios.get( '/api/upload_file/' + eventId )
        .then( upload => {
            files = upload.data.upload ;
        });
        
        Promise.all([ p1, p2 ])
        .then(() => {
            this.setState({ eventId: data.id, location: { lat: lat, lon: lng }, start: start, end: end, resources: files }) ;
            console.log( "EventHubTabView: Event data loaded." ) ;
        }) ;
    }
    
    componentDidMount() {
        this.getEventData( this.props.eventId ) ;
    }

    shouldComponentUpdate() {
        return ( null != this.props.eventId ) ;
    }

    conponentDidUpdate( prevProps, prevState ) {
        if ( prevProps.eventId != this.props.eventId ) {
            this.getEventData( this.props.eventId ) ;
        }
    }
    
    render() {
        const { classes } = this.props ;
        const { resources, 
                image, 
                eventId, 
                tabValue,
                location } = this.state ;
                                
        const ToggleFullScreen = () => {
            if ( !this.state.fullScreen ){
                return (
                    <GridList cellHeight={ 200 } cols={ 2 } className={ classes.imagelist }>
                        { resources && resources.map(( resource, index ) => {
                            return(
                            <Button onClick={() => this.toggleScreen( resource )} >
                                <GridListTile key={ "img" + index } cols={ this.getCol( index )}>
                                    <img src={ resource.url } style={{ height: '200px', width: '200px' }} alt="img not found" />
                                </GridListTile>
                            </Button> ) ;
                        })} 
                    </GridList>
                );
             } else {
                 return(
                     <div>
                         <Button onClick={ () => this.toggleScreen()}>
                             <img src={ image } />
                         </Button>
                     </div>
                 );
            } 
        } ;

        const tabPanels = [
            { label: 'Map',
              contents:   ( <Grid container justify="space-around" alignItems="center"><EventMap key={ Date.now()} eventId={ eventId } readOnly={ true } /></Grid> )
            }, 
            { label: 'Chat',
              contents:   ( <Chat /> )
            }, 
            { label: 'Resources',
              contents:   ( <Grid container justify="space-around" alignItems="center"><ToggleFullScreen /></Grid> )
            }, 
            { label: 'Weather',
              contents:   ( <Weather lat={ location.lat } lon={ location.lon }/> )
            }
        ] ;

        return (
            <Paper variant="outlined" style={{ width: '100%', height: 650 }}>
                <Tabs value={ tabValue }  aria-label="change component" onChange={( event, newValue ) => this.handleTabChange( event, newValue )} >
                    { tabPanels.map(( tabData, index ) => {
                        return (
                            <Tab tabIndex={ index } label={ tabData.label } style={{ 'min-width': '120px' }} /> 
                        ) ;
                    })}
                </Tabs>
                { tabPanels.map(( tabData, index ) => {
                    // return (<TabPanel index={ index } value={ tabValue } hidden={ tabValue != index }><Typography style={{ width: '100%' }}>{ tabData.contents }</Typography></TabPanel>) ;
                    return ( <EventHubTabPanel index={ index } value={ tabValue } children= { tabData.contents } /> ) ;
                })}
            </Paper>
        ) ;
    }
}

export default withStyles( useStyles, { withTheme: true })( EventHubTabView ) ;