import React from 'react';
import List from '@material-ui/core/List';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, withStyles, createMuiTheme } from '@material-ui/core/styles';



const items = [
    {
        name: 'home',
        label: 'Home',
        link: '/',
        items: [
        ],
    },
    {
        name: 'events',
        label: 'Events',
        link: '/events',
        items: [
            { name: 'createevent', label: 'Create Event', link: '/createevent' },
            { name: 'archived', label: 'Archived Event', link: '/archivedevent' },
            { name: 'eventhub', label: 'Event Hub', link: '/eventhub' },
        ],
    },
    {
        name: 'users',
        label: 'My Users',
        link: '/userlist',
        items: [
            { name: 'importusers', label: 'Import Users', link: '/importusers' }
        ],
    },
    {
        name: 'groups',
        label: 'My Groups',
        link: '/groups',
    },
]

const theme = createMuiTheme({

});


const StyledListItem = withStyles((theme) => ({
    root: {
        backgroundColor: '#F3F4F8',
        margin: 5,
        width: '94%',
        borderLeft: '5px solid #848387',
        fontFamily: 'Arial, Helvetica, sans-serif',
        '&:hover': {
            backgroundColor: 'white',
            color: '#12779f',
        },
    },
}))(ListItem);

const overideStyles = makeStyles({
    sideNavSection: {
        height: 60,
        color: '#848387',
        '& span': {
            fontSize: '1.2em',
        },
    },
    sideNavSubSection: {
        height: 40,
        color: '#848387',
        '& span': {
            fontSize: '1em',
        },
    },
    sidebar: {
        width: '15%',
        height: '100%',
        position: 'absolute',
        backgroundColor: '#DFE3EA',
        display: 'inline-block',
        '& a': {
            textDecoration: 'none !important',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
            width: '0%'
          },
        
    },

});

function SidebarItem({ label, items, depthStep = 30, depth = 0, myLink, ...rest }) {
    const classes = overideStyles();
    return (
        <>
            <Link to={myLink}>
                <StyledListItem button dense {...rest}

                >
                    <ListItemText style={{
                        paddingLeft: depth * depthStep,
                        color: '#022958',
                    }}>
                        <span>{label}</span>
                    </ListItemText>
                </StyledListItem>
            </Link>
            {Array.isArray(items) ? (
                <List disablePadding dense

                >
                    {items.map((subItem) => (
                        <SidebarItem
                            className={classes.sideNavSubSection}
                            key={subItem.name}
                            depth={depth + 0.5}
                            depthStep={depthStep}
                            myLink={subItem.link}
                            {...subItem}
                        />
                    ))}
                </List>
            ) : null}
        </>
    )
}

function Sidebar({ depthStep, depth }) {
    const classes = overideStyles();
    return (

        <div  className={classes.sidebar}>
            <List disablePadding dense
            >
                {items.map((sidebarItem, index) => (
                    <SidebarItem
                        className={classes.sideNavSection}
                        key={`${sidebarItem.name}${index}`}
                        depthStep={depthStep}
                        myLink={sidebarItem.link}
                        depth={depth}
                        {...sidebarItem}
                        variant="body1"
                    />
                ))}
            </List>
        </div>
    )
};

export default Sidebar;
