import React, { Component } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core/'; 
import Avatar from '@material-ui/core/Avatar';
import { Link } from 'react-router-dom';

const useStyles = theme => ({
    table: {
        minWidth: 650,
    },
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        minWidth: 650,
    }
});


class About extends Component {

    render() {
        return (
            <TableContainer component={Paper}>
                <AppBar position="static">
                    <Toolbar>
                        <Grid justify='space-between' container spacing={24}>
                            <Grid item>
                                <Typography variant="h5" >
                                    About OIEMS
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <div className="aboutSection">
                    <div className="learnAbout">
                        <Grid item m zeroMinWidth>
                            <Typography variant="h6" align="center">
                                Learn About OIEMS
                            </Typography>
                            <Typography variant="body1">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sit amet justo donec enim diam vulputate ut. Tempor orci dapibus ultrices in iaculis nunc. Integer malesuada nunc vel risus commodo viverra maecenas accumsan. In aliquam sem fringilla ut morbi tincidunt.
                            </Typography>
                        </Grid>
                    </div>
                    <div className="ourMission">
                        <Grid item m zeroMinWidth>
                            <Typography variant="h6" align="center">
                                Our Mission
                            </Typography>
                            <Typography variant="body1">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sit amet justo donec enim diam vulputate ut. Tempor orci dapibus ultrices in iaculis nunc. Integer malesuada nunc vel risus commodo viverra maecenas accumsan. In aliquam sem fringilla ut morbi tincidunt.
                            </Typography>
                        </Grid>
                    </div>
                    <div className="ourLeadership">
                        <Grid item m zeroMinWidth className="leadership">
                            <div className="leaderInfo">
                                <Grid item>
                                    <Avatar>O</Avatar>
                                </Grid>
                                <Typography variant="subtitle1" align="left">
                                    Name
                                </Typography>
                                <Typography variant="subtitle2" align="left">
                                    CEO
                                </Typography>
                            </div>
                            <div className="leaderText">
                                <Typography variant="h6" align="center">
                                    Our Leadership
                                </Typography>
                                <Typography variant="body1">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sit amet justo donec enim diam vulputate ut. Tempor orci dapibus ultrices in iaculis nunc. Integer malesuada nunc vel risus commodo viverra maecenas accumsan. In aliquam sem fringilla ut morbi tincidunt.
                                </Typography>
                            </div>
                        </Grid>
                    </div>
                    <div className="seeAction">
                        <Grid item s zeroMinWidth>
                            <Typography variant="h6" align="center">
                                Want to see us in action?
                            </Typography>
                            <Link to='requestdemo' style={{ textDecoration: 'none' }}>
                                <Button 
                                    style ={{ 
                                        display: "flex",
                                        justifyContent: "center",
                                        backgroundColor: "#3f51b5",
                                        color: "white",
                                        margin: "auto",
                                    }}
                                >Request a demo</Button>
                            </Link>
                        </Grid>
                    </div>
                </div>
            </TableContainer>
        );
    }
}
export default withStyles(useStyles, { withTheme: true })(About);