import React, { Component } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import AppBar from '@material-ui/core/AppBar';
import ToolBar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import DemoForm from './DemoForm';

class RequestDemo extends Component {

    render(){
        return(
            <TableContainer component={Paper}>
                <AppBar position="static">
                    <ToolBar>
                        <Grid justify='space-between' container spacing={24}>
                            <Grid item>
                                <Typography variant="h5">
                                    Request a Demo
                                </Typography>
                            </Grid>
                        </Grid>
                    </ToolBar>
                </AppBar>
                <Box component="span" m={1}>
                    <Typography variant="h6" align="center">Feel free to reach out to us to request a demo</Typography>
                </Box>
                <Grid item m zeroMinWidth className="demoBottom">
                    <Box flexGrow={1} className="demoForm">
                        <DemoForm />
                    </Box>
                </Grid>
            </TableContainer>
        );
    }
}

export default RequestDemo;