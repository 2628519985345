
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
//import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import CardActions from '@material-ui/core/CardActions';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const useStyles = theme => ({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 10,
    },
    pos: {
        marginBottom: 12,
    },
    linkMargin: {
        margin: '5px',
    },
    btnStyle: {
        textDecoration: 'none',
    },
});

class EventCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            firstName: '',
            middleInitial: '',
            lastName: '',
            rank: '',
            email: '',
            phoneNumber: '',
            supervisor: '',
            role: '',
            organization: '',
            address: '',
            city: '',
            state: '',
            zipCode: '',
            birthdate: '',
            sex: '',
            inSystem: ''
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    onSubmit = async () => {
        const firstName = this.state.firstName;
        const middleInitial = this.state.middleInitial;
        const lastName = this.state.lastName;
        const rank = this.state.rank;
        const email = this.state.email;
        const phoneNumber = this.state.phoneNumber;
        const supervisor = this.state.supervisor;
        const role = this.state.role;
        const organization = this.state.organization;
        const address = this.state.address;
        const city = this.state.city;
        const state = this.state.state;
        const zipCode = this.state.zipCode;
        const birthdate = this.state.birthdate;
        const sex = this.state.sex;
        const inSystem = this.state.inSystem;

        const params = JSON.stringify({
            firstName: firstName,
            middleInitial: middleInitial,
            lastName: lastName,
            rank: rank,
            email: email,
            phoneNumber: phoneNumber,
            supervisor: supervisor,
            role: role,
            organization: organization,
            address: address,
            city: city,
            state: state,
            zipCode: zipCode,
            birthdate: birthdate,
            sex: sex,
            inSystem: inSystem
        });

        console.log(this.props.match.params.id);

        await axios.put('/api/roster/' + this.props.match.params.id, params, {
            headers: { 'Content-Type': 'application/json' }
        });

        this.props.history.push('/userview/' + this.state.id);
    }

    handleChange = (evt) => {
        evt.preventDefault();
        this.setState({ [evt.target.name]: evt.target.value });
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        const firstName = this.props.match.params.firstName || '';
        const middleInitial = this.props.match.params.middleInitial || '';
        const lastName = this.props.match.params.lastName || '';
        const rank = this.props.match.params.rank || '';
        const email = this.props.match.params.email || '';
        const phoneNumber = this.props.match.params.phoneNumber || '';
        const supervisor = this.props.match.params.supervisor || '';
        const role = this.props.match.params.role || '';
        const organization = this.props.match.params.organization || '';
        const address = this.props.match.params.address || '';
        const city = this.props.match.params.city || '';
        const state = this.props.match.params.state || '';
        const zipCode = this.props.match.params.zipCode || '';
        const birthdate = this.props.match.params.birthdate || '';
        const sex = this.props.match.params.sex || '';
        const inSystem = this.props.match.params.inSystem || '';
        this.setState({ id: id, firstName: firstName, middleInitial: middleInitial, lastName: lastName, rank: rank, email: email, phoneNumber: phoneNumber, supervisor: supervisor, role: role, organization: organization, address: address, city: city, state: state, zipCode: zipCode, birthdate: birthdate, sex: sex, inSystem: inSystem });
    }

    render() {
        const { classes } = this.props;

        return (
            <Card className={classes.root} variant="outlined">
                <CardContent justify='center'>
                    <form>
                        <Grid spacing={2}>
                            <Grid container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <Typography variant="h4" >
                                    Edit User Details
                         </Typography>
                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <TextField className="textFieldAlt" label="First Name" variant="outlined" name="firstName" defaultValue={this.props.match.params.firstName} InputLabelProps={{ shrink: true }} onInput={this.handleChange} />
                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <TextField className="textFieldAlt" label="Middle Initial" variant="outlined" name="middleInitial" defaultValue={this.props.match.params.middleInitial} InputLabelProps={{ shrink: true }} onInput={this.handleChange} />
                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <TextField className="textFieldAlt" label="Last Name" variant="outlined" name="lastName" defaultValue={this.props.match.params.lastName} InputLabelProps={{ shrink: true }} onInput={this.handleChange} />
                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <TextField className="textFieldAlt" label="Rank" variant="outlined" name="rank" defaultValue={this.props.match.params.rank} InputLabelProps={{ shrink: true }} onInput={this.handleChange} />
                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <TextField className="textFieldAlt" label="Email" variant="outlined" name="email" defaultValue={this.props.match.params.email} InputLabelProps={{ shrink: true }} onInput={this.handleChange} />
                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <TextField className="textFieldAlt" label="Phone Number" variant="outlined" name="phoneNumber" defaultValue={this.props.match.params.phoneNumber} InputLabelProps={{ shrink: true }} onInput={this.handleChange} />
                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <TextField className="textFieldAlt" label="Supervisor" variant="outlined" name="supervisor" defaultValue={this.props.match.params.supervisor} InputLabelProps={{ shrink: true }} onInput={this.handleChange} />
                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <TextField className="textFieldAlt" label="Role" variant="outlined" name="role" defaultValue={this.props.match.params.role} InputLabelProps={{ shrink: true }} onInput={this.handleChange} />
                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}> <TextField className="textFieldAlt" label="Organization" variant="outlined" name="organization" defaultValue={this.props.match.params.organization} InputLabelProps={{ shrink: true }} onInput={this.handleChange} />
                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}> <TextField className="textFieldAlt" label="Address" variant="outlined" name="address" defaultValue={this.props.match.params.address} InputLabelProps={{ shrink: true }} onInput={this.handleChange} />
                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}> <TextField className="textFieldAlt" label="City" variant="outlined" name="city" defaultValue={this.props.match.params.city} InputLabelProps={{ shrink: true }} onInput={this.handleChange} />
                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}> <TextField className="textFieldAlt" label="State" variant="outlined" name="state" defaultValue={this.props.match.params.state} InputLabelProps={{ shrink: true }} onInput={this.handleChange} />
                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}> <TextField className="textFieldAlt" label="Zip Code" variant="outlined" name="zipCode" defaultValue={this.props.match.params.zipCode} InputLabelProps={{ shrink: true }} onInput={this.handleChange} />
                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}> <TextField className="textFieldAlt" type="date" label="Birthdate" variant="outlined" name="birthdate" defaultValue={moment(this.props.match.params.birthdate).format("YYYY-MM-DD")} InputLabelProps={{ shrink: true }} onInput={this.handleChange} />
                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}> <TextField className="textFieldAlt" label="Sex" variant="outlined" name="sex" defaultValue={this.props.match.params.sex} InputLabelProps={{ shrink: true }} onInput={this.handleChange} />
                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}> <TextField className="textFieldAlt" id="read-only-input" label="In System" variant="outlined" name="inSystem" defaultValue={this.props.match.params.inSystem} InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true }} onInput={this.handleChange} />
                            </Grid>

                            <CardActions>
                            <Grid className="gridMarginAlt" container
                                    spacing={2}
                                    direction="row"
                                    justify="center"
                                    alignItems="center" xs={12}>
                              <Grid item>
                                <NavLink  style={{ textDecoration: 'none' }} to={'/userview/' + this.state.id} >
                                    <Button  color="secondary" variant="outlined"  size="large">Cancel</Button>
                                </NavLink>
                                </Grid>
                                <Grid item>
                                <NavLink style={{ textDecoration: 'none' }} to={'/userview/' + this.state.id} > 
                                 <Button color="primary" variant="outlined"  size="large" onClick={this.onSubmit}>Save</Button>
                                 </NavLink>
                                 </Grid>
                                </Grid>
                            </CardActions>
                        </Grid>
                    </form>
                </CardContent>
                {/*<CardActions>
                    <Button size="small">Event Details</Button>
                </CardActions>*/}
            </Card>
        )
    }
}
export default withStyles(useStyles, { withTheme: true })(EventCard);
