import React, { Component } from 'react';
import { withRouter } from 'react-router';

class Logout extends Component {
    render(){
        return(
            <div>
                <h1>Successfully logged out</h1>
            </div>
        )
    }
}
export default withRouter(Logout);