import React, { Component } from 'react';
import { fade, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import ArchiveIcon from '@material-ui/icons/Archive';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ConfirmDialog from './ConfirmDialog'
import { NavLink, useHistory } from 'react-router-dom';
import { getFormattedDateTime } from '../FormatDate';


const overideStyles = theme => ({
    table: {
      minWidth: 650,
    },
    tableDataStyle: {
      fontSize: '1em'
    },
    toolbarButtons: {
      marginRight: 'auto',
    },
    appHeader: {
      backgroundColor: '#192d56',
    },
    appBarButton: {
      color: 'white',
      border: '1px solid white',
      margin: '5px',
    },
    title: {
      float: 'left',
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
   
      marginRight: theme.spacing(2),
      marginLeft: 0,
   
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  });

  const StyledTableCell = withStyles((theme) => ({
      head: {
          backgroundColor: '#12779f',
          color: theme.palette.common.white,
          fontSize: '1.25em',
     
      },
      body: {
   
        fontSize: '1.25em',
      },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
      root: {
        color: 'slategray',
          '&:nth-of-type(odd)': {
              // backgroundColor: ''theme.palette.action.hover'',
              backgroundColor: '#F3F4F8',
          },
          '&:nth-of-type(even)': {
            backgroundColor: '#E6E8E9',
        },
        '&:hover': {
        backgroundColor: 'white',
        cursor: 'pointer',
      
        },
        '& a': {
          color: '#3d3d3d',
        },
        '&:hover a': {
          color: '#12779f',
        },
      },
  }))(TableRow);

class EventTable extends Component {
  constructor(props){
    super(props)
    this.state = {
      id: '',
      confirmOpen: false, 
      rows: [],
      checked: [],
      toDelete: [],
      ownerid: ''
    }
    this.onDelete = this.onDelete.bind(this);
    this.onArchive = this.onArchive.bind(this);
  }
    
  updateList = () => {
    axios.get('/api/event/ownerevents/' + this.state.ownerid)
      .then(res => {
        const data = res.data.event;
        //let test = [];
        this.setState({ rows: data });
        data.forEach(item => {
          let checkedItems = this.state.checked.concat(false);
          this.setState({ checked: checkedItems});
        });
      });
  };

  //Deleting event 
  async componentDidMount() {
    const currentUser = await axios.get('/api/session/');
    const ownerid = currentUser.data.user.id;
    this.setState({ ownerid: ownerid });

    axios.get('/api/event/ownerevents/' + ownerid)
      .then(res => {
        const data = res.data.event;
        console.log(data);
        this.setState({ rows: data });
        data.forEach(item => {
          let checkedItems = this.state.checked.concat(false);
          this.setState({ checked: checkedItems});
        });
      })
      .catch(error => {
        console.log(error);
      });
  } 
  
  setConfirmOpen = (state) => {
    this.setState( { confirmOpen: state });
  }

  onArchive = (event) => {
    event.preventDefault();
    let params = JSON.stringify({
      archived: 1
    });

    this.state.toDelete.forEach(item => {
      //console.log(item);
      axios.put('/api/event/' + item, params, {
        headers: { 'Content-Type': 'application/json' }
      });
    });

    this.updateList();
  }

  onDelete = (event) => {
    // event.preventDefault();

    this.state.toDelete.forEach(item => {
      axios.delete('/api/event/' + item)
      .then(res => {
        //console.log(res);
        //console.log(res.data);
      });
    });
    this.updateList();
  }

  isEventActive = ( event ) => {
      if ( event === null ) return false ;
      
      var currentTime = new Date() ;
      var startTime   = new Date( event.start ) ;
      var endTime     = new Date( event.end ) ;
      
      return (( startTime <= currentTime ) && ( currentTime < endTime )) ;
  }
  
  getEventProps( event, start, end, isSelected ) {
      var eventBackgroundColor = ( this.isEventActive( event )) ? '#6af072' : '#02759a' ;
      
      return ({ style: { backgroundColor: eventBackgroundColor }}) ;
  }

  render(){
    const { classes } = this.props;
    
    return (
      <TableContainer component={Paper}>
        <AppBar position="static" className={classes.appHeader}>
        <Toolbar> 
          <Grid justify='space-between' container spacing={24}>
            <Grid item>
              <Typography variant="h4" className={classes.title}>
                List of Events
              </Typography>
            </Grid>
            <Grid container direction="row" justify="flex-end" alignItems="center" xs={8}>
            <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
                <Button variant="outlined"  className={classes.appBarButton} onClick={() => this.setConfirmOpen(true)}>
                  <DeleteForeverOutlinedIcon fontSize="large" /> Delete
                </Button>
                <ConfirmDialog
                    title="Delete Event?"
                    children="Are you sure you want to delete this event?"
                    open={this.state.confirmOpen}
                    setOpen={this.setConfirmOpen}
                    onConfirm={this.onDelete}
                  >
                    Are you sure you want to delete this event?
                  </ConfirmDialog>
                <Button variant="outlined"  className={classes.appBarButton} onClick={this.onArchive}>
                  <ArchiveIcon fontSize="large" /> Archive
                </Button>
                <NavLink to="/createevent"><Button variant="outlined" aria-label="Add Event" className={classes.appBarButton}>
                  <AddBoxOutlinedIcon fontSize="large"/>  Add Event
                </Button></NavLink>
                
            </Grid>
          </Grid>
        </Toolbar>
        </AppBar>
        <Table className={classes.table} aria-label="event list table">
          <TableHead>
            <TableRow>
                    <StyledTableCell>Select</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Type</StyledTableCell>
                    <StyledTableCell>
                        <span>Starts</span>
                        <br />
                        <span>Ends</span>
                    </StyledTableCell>
                    <StyledTableCell>Location</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.rows && this.state.rows.map((row, index) => {
                var locationName = ( row.location ) ? JSON.parse( row.location ).name : "" ;
                var highlightRowStyle = {} ;
                
                if ( this.isEventActive( row )) {
                    highlightRowStyle = { backgroundColor: '#6af072' } ;
                }

                return (
                    <StyledTableRow key={row.name} style={highlightRowStyle}>
                        <TableCell>
                          <Checkbox 
                            key={index}
                            checked={this.state.checked[index]}
                            onChange={e => {
                                //console.log(e.target.checked);
                        
                                this.setState({ checked: e.target.checked });
                                if(e.target.checked === true){
                                  if(this.state.toDelete.some(item => row.id === item) === false){
                                    let joined = this.state.toDelete.concat(row.id);
                                    this.setState({ toDelete: joined });
                                  }
                                }
                                if(e.target.checked === false){
                                  if(this.state.toDelete.some(item => row.id === item)){
                                    var array = [...this.state.toDelete];
                                    var index = array.indexOf(row.id);

                                    if(index !== -1){
                                      array.splice(index, 1);
                                      this.setState({ toDelete: array });
                                    }
                                  }
                                }
                              }
                             }/>
                        </TableCell>
                        <StyledTableCell component="th" scope="row"><NavLink to={"/eventview/" + row.id} activeClassName="EventViewSelected">
                          {row.name}
                        </NavLink></StyledTableCell>
                        <StyledTableCell>{row.type}</StyledTableCell>
                        <StyledTableCell>
                                <span>{getFormattedDateTime(row.start)}</span>
                                <br />
                                <span>{getFormattedDateTime(row.end)}</span>
                        </StyledTableCell>
                        <StyledTableCell>{locationName}</StyledTableCell>
                        <StyledTableCell>{row.status}</StyledTableCell>
                      </StyledTableRow>
                    )})}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
export default withStyles(overideStyles, { withTheme: true })(EventTable);
