import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import L from 'leaflet' ;
import 'sdleafletdraw' ;
import 'sdleafletdraw/dist/leaflet.draw.css';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet-geosearch/dist/geosearch.css' ;

const useStyles = theme => ({
    root: {
        minWidth: 275,
        width: "50%",
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    linkMargin: {
        margin: '5px',
    },
    btnStyle: {
        textDecoration: 'none',
    },
    mapDiv: {
      width: '498px',
      height: '480px'
    },
    mapRegionInput: {
      display: 'none'
    },
    perimeterPointsInput: {
      display: 'none'
    },
    poisInput: {
      display: 'none'
    }
});

const defaultLocation = {
    name: "",
    map_region: {
      lat: 38.889260228566236,
      lng:-77.0501760013805,
      deltaLat: 0.003,
      deltaLng: 0.004,
    },
    perimeter_polygon: [],
    points_of_interest: []
} ;
    
const generateUUID = () => {
  let
    d  = new Date().getTime(),
    d2 = ( performance && performance.now && ( performance.now() * 1000 )) || 0 ;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace( /[xy]/g, c => {
    let r = Math.random() * 16 ;
    if ( d > 0 ) {
      r = ( d + r ) % 16 | 0 ;
      d = Math.floor( d / 16 ) ;
    } else {
      r  = ( d2 + r ) % 16 | 0 ;
      d2 = Math.floor( d2 / 16 ) ;
    }
    return ( c == 'x' ? r : ( r & 0x7 | 0x8 )).toString( 16 ) ;
  });
};

class EventMap extends Component {
    _eventMap = null ;
    _disableChangeEvent = false ;
    _editableLayers = null ;
    _perimeterLayers = null ;
    _poiLayers = null ;
    _event = {
      name: '',
      code: '',
      start: '',
      end: '',
      location: defaultLocation,
      status: '',
      roster: []
    } ;
            
    constructor( props ) {
        super( props ) ;
        
        this.state = {
            eventMapId: generateUUID(),
            eventMap: null,
            eventId: parseInt( props.eventId ),
            readOnly: props.readOnly,
        } ;

        this.handleAddressChange = this.handleAddressChange.bind( this ) ;
        
        document.addEventListener( "eventAddressChange", this.handleAddressChange, false ) ;
    }

    getMapId = () => {
        return "eventMapDiv" + (( this.state.eventMapId ) ? "-" + this.state.eventMapId : "" ) ;
    }
    
    getLocationNameId = () => {
        return "locationNameInput" + (( this.state.eventMapId ) ? "-" + this.state.eventMapId : "" ) ;
    }
    
    onSubmit = () => {
        const params = JSON.stringify({
            name: this._event.location.name,
            map_region: this._event.location.map_region,
            polygonPoints: this._event.location.perimeter_polygon,
            pois: this._event.location.points_of_interest,
        });
    }

    handleAddressChange = ( e ) => {
        e.stopPropagation();

        // handle location sub fields from the map
        switch ( e.target.name ) {
        case 'event-location-name' :
            this._event.location.name = e.target.value ;
            break ;
        default:
            break ;
        }
      
        this.triggerLocationChangeEvent() ;
    }
    
    triggerLocationChangeEvent = () => {
      if ( this._disableChangeEvent )  return ;
      
      var locationEvent = new CustomEvent( "eventLocationChange",
        {
          detail: {
            message: "Event Location Change",
            value: JSON.stringify( this._event.location )
          },
          bubbles: true,
          cancelable: true
        }
      ) ;
    
      document.getElementById( this.getMapId()).dispatchEvent( locationEvent ) ;
    }

    mapBounds = () => {
      var region = this._event.location.map_region ;
      var lat    = region.lat ;
      var lon    = region.lng ;
      var latD2  = region.deltaLat / 2.0 ;
      var lonD2  = region.deltaLng / 2.0 ;
  
      return([[ lat + latD2, lon - lonD2 ], [ lat - latD2, lon + lonD2 ]]) ;
    }

    setMarkerInputValue = () => {
      var poiValues = [] ;
      this._poiLayers.eachLayer(( marker ) => {
        var latLng = marker.getLatLng() ;
        poiValues.push({ title: marker.title, lat: latLng.lat, lng: latLng.lng }) ;
      }) ;
  
      this._event.location.points_of_interest = poiValues ;
      
      // var inputElement = document.getElementById( "poisInput" ) ;
      // if ( inputElement ) {
      //   inputElement.value = (( poiValues.length > 0 ) ? JSON.stringify( poiValues ) : "" ) ;
      //   inputElement.trigger( "change" ) ;
      // }
    }
    
    boundsToMapRegion = ( latLngBounds ) => {
      var lat1      = latLngBounds.getSouth() ;
      var lng1      = latLngBounds.getWest() ;
      var lat2      = latLngBounds.getNorth() ;
      var lng2      = latLngBounds.getEast() ;
      var deltaLat  = Math.abs( lat1 - lat2 ) ;
      var deltaLng  = Math.abs( lng1 - lng2 ) ;
      var minLat    = lat1 < lat2 ? lat1 : lat2 ;
      var minLng    = lng1 < lng2 ? lng1 : lng2 ;
      var centerLat = minLat + ( deltaLat / 2 ) ;
      var centerLng = minLng + ( deltaLng / 2 ) ;
      
      return { lat: centerLat, lng: centerLng, deltaLat: deltaLat, deltaLng: deltaLng } ;
    } ;
    
    bounds_changed = ( e ) => {
      this._event.location.map_region = this.boundsToMapRegion( this._eventMap.getBounds()) ;
      this.triggerLocationChangeEvent() ;
 
      console.log( "map bounds changed" ) ;
    } ;
    
    draw_created = ( e ) => {
      var newType  = e.layerType ;
      var newLayer = e.layer ;

      L.setOptions( newLayer, { layerType: newType }) ;

      switch( newType ) {
      case 'marker' :
        this._poiLayers.addLayer( newLayer ) ;
        this.setMarkerInputValue() ;
        this.triggerLocationChangeEvent() ;
        console.log( "POI added." ) ;
        break ;
      case 'polygon' :
        this._perimeterLayers.clearLayers() ;
        this._perimeterLayers.addLayer( newLayer ) ;

        this._event.location.perimeter_polygon = newLayer.getLatLngs() ;
        this.triggerLocationChangeEvent() ;

        console.log( "Perimeter added" ) ;
        break ;
      default:
        break ;
      }
    } ;

    draw_edited = ( e ) => {
      var editedLayer = e.layer ;

      switch( editedLayer.options.layerType ) {
      case 'marker' :
        this.setMarkerInputValue() ;
        this.triggerLocationChangeEvent() ;
        console.log( "POI edited." ) ;
        break ;
      case 'polygon' :
        this._event.location.perimeter_polygon = editedLayer.getLatLngs() ;
        this.triggerLocationChangeEvent() ;

        console.log( "Perimeter edited" ) ;
        break ;
      default:
        break ;
      }
    } ;

    draw_deleted = ( e ) => {
      console.log( "draw:deleted called" ) ;
      var deletedLayers = e.layers ;

      console.log(" deleted!" ) ;
      deletedLayers.eachLayer(( deletedLayer ) => {
        switch ( deletedLayer.options.layerType ) {
          case 'marker' :
            this._poiLayers.removeLayer( deletedLayer ) ;
            this.setMarkerInputValue() ;
            this.triggerLocationChangeEvent() ;
            console.log( "POI deleted" ) ;
            break ;
          case 'polygon' :
            this._perimeterLayers.clearLayers() ;
            this._event.location.perimeter_polygon = [] ;
            this.triggerLocationChangeEvent() ;

            console.log( "Perimeter deleted" ) ;
            break ;
          default:
            console.log( "Unexpected type - draw:deleted" ) ;
            break ;
        }
      }) ;
    } ;

    locationEntered = ( e ) => {
        console.log( "locationEntered() called. name = " + e.location.label ) ;
        
        document.getElementById( this.getLocationNameId()).value = e.location.label ;

        // the input event for locationNameInput is not firing, is this because it's read-only?
        var inputEvent = new InputEvent( "input",
            {
                data: e.location.label,
                inputType: 'insertText',
                isComposing: false,
                bubbles: true,
                cancelable: true,
            }
        ) ;
        document.getElementById( this.getLocationNameId()).dispatchEvent( inputEvent ) ;
    } ;
    
    initializeMap = () => {
      console.log( "Initializing Event Map" ) ;
      this._disableChangeEvent = true ;
      
      var mapRegion  = this._event.location.map_region ;
      var mapOptions = { 
        center: [ mapRegion.lat, mapRegion.lng ], 
        zoom: 13, 
        scrollWheelZoom: true
      } ;
  
      var mapDiv = document.getElementById( this.getMapId()) ;
      this._eventMap = new L.Map( mapDiv, mapOptions ) ;
  
      mapDiv.setAttribute( 'mapInitialized', true ) ;
  
      this._editableLayers  = L.featureGroup() ;
      this._perimeterLayers = L.featureGroup() ;
      this._poiLayers       = L.featureGroup() ;

      this._editableLayers.addLayer( this._perimeterLayers ) ;
      this._editableLayers.addLayer( this._poiLayers ) ;
      this._editableLayers.addTo( this._eventMap ) ;
  
      var streetMapOptions = { 
        maxZoom: 18, 
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' 
      } ;
  
      var satelliteMapOptions = { 
        attribution: 'google' 
      } ;
  
      L.control.layers(
        { 
          "Street Map": L.tileLayer( 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', streetMapOptions ).addTo( this._eventMap ), 
          "Satellite":  L.tileLayer( 'https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}', satelliteMapOptions ) 
        }, 
        { 
          'Show Perimiter': this._perimeterLayers,
          'Show POIs': this._poiLayers
        }, 
        { 
          position: 'bottomleft', 
          collapsed: false 
        }
      ).addTo( this._eventMap ) ;
      
      const provider = new OpenStreetMapProvider() ;
      
      const searchControl = new GeoSearchControl({ 
          provider: provider, // required
          style: 'button', // optional: 'bar'|'button' - default 'button'
          autoComplete: true, // optional: true|false - default true
          autoCompleteDelay: 250, //optional: number  - default: 250
      }).addTo( this._eventMap ) ;
            
      if ( !this.state.readOnly ) {
        var drawOptions = {
          edit: {
            featureGroup: this._editableLayers,
            edit: false
          },
          draw: {
            featureGroup: this._editableLayers,
            polyline: false,
            polygon: {
              allowIntersection: true,
              shapeOptions: {
                color: "red"
              }
            },
            marker: {
              allowIntersection: true,
              shapeOptions: {
                color: "red"
              }
            },
            circle: false,
            circlemarker: false,
            rectangle: false
          }
        } ;

        var drawControl = new L.Control.Draw( drawOptions ) ;
        this._eventMap.addControl( drawControl ) ;
        
        this.bounds_changed = this.bounds_changed.bind( this ) ;
        this._eventMap.on( 'zoomend', this.bounds_changed ) ;
        this._eventMap.on( 'moveend', this.bounds_changed ) ;
        this._eventMap.on( 'resize', this.bounds_changed ) ;

        this.draw_created = this.draw_created.bind( this ) ;
        this._eventMap.on( L.Draw.Event.CREATED, this.draw_created ) ;

        this.draw_edited = this.draw_edited.bind( this ) ;
        this._eventMap.on( 'draw:edited', this.draw_edited ) ;
        
        this.draw_deleted = this.draw_deleted.bind( this ) ;
        this._eventMap.on( 'draw:deleted', this.draw_deleted ) ;
      }
      
      this._eventMap.on( 'geosearch/showlocation', this.locationEntered );
      
      this._disableChangeEvent = false ;
    }
    
    renderMap = () => {
      console.log( "Rendering Map" ) ;
      this._disableChangeEvent = true ;
      
      this._eventMap.fitBounds( this.mapBounds()) ;

      console.log( `EventMap: rendering ${ this._event.location.perimeter_polygon.length } polygons.` ) ;
      if ( this._event.location.perimeter_polygon.length > 0 ) {
        var newPolygon = L.polygon( this._event.location.perimeter_polygon, { color: "#ff0000" }) ;
        L.setOptions( newPolygon, { layerType: 'polygon' }) ;
        newPolygon.addTo( this._perimeterLayers ) ;
      }

      console.log( `EventMap: rendering ${ this._event.location.points_of_interest.length } POIs.` ) ;
      // draw the points of interest
      this._event.location.points_of_interest.forEach(( poi ) => {
        var newMarker = L.marker([ poi.lat, poi.lng ], { title: poi.title })
        L.setOptions( newMarker, { layerType: 'marker' }) ;
        newMarker.addTo( this._poiLayers ) ;    
      }) ;
      this.setMarkerInputValue() ;
      
      this._disableChangeEvent = false ;
    }

    componentDidMount() {
        const eventId  = this.state.eventId ;
        
        this.initializeMap() ;
        
        if (( eventId != NaN ) && ( eventId > 0 )) {
          axios.get( '/api/event/' + eventId )
            .then( res => {
              const data = res.data.event ;
              this._event.location = JSON.parse( data.location ) ;
          
              this.renderMap() ;
            }) ;
        }
        else {
          this.renderMap() ;
        }
    }
    
    componentDidUpdate = ( prevProps, prevState ) => {
        if (( prevProps.eventId != this.props.eventId ) && ( this.props.eventId != NaN ) && ( this.props.eventId > 0 )) {
            this.renderMap() ;
        }
    }
    
    shouldComponentUpdate = ( newProps, nextState ) => {
      var eventId = parseInt( newProps.eventId ) ;
      return (( newProps.eventId != this.props.eventId ) && ( eventId != NaN ) && ( eventId > 0 )) ;
    }

    render() {
        const { classes } = this.props ;

        var head                  = document.getElementsByTagName( 'head' )[ 0 ] ;
        var leafletLink           = document.createElement( 'link' ) ;
        leafletLink.rel           = "stylesheet" ;
        leafletLink.href          = "https://unpkg.com/leaflet@1.7.1/dist/leaflet.css" ;
        leafletLink.integrity     = "sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A==" ;
        leafletLink.crossOrigin   = "anonymous" ;
  
        var leafletDrawLink       = document.createElement( 'link' ) ;
        leafletDrawLink.rel       = "stylesheet" ;
        leafletDrawLink.href      = "//cdnjs.cloudflare.com/ajax/libs/leaflet.draw/1.0.4/leaflet.draw.css" ;
  
        var leafletScript         = document.createElement( 'script' ) ;
        leafletScript.src         = "https://unpkg.com/leaflet@1.7.1/dist/leaflet.js" ;
        leafletScript.integrity   = "sha512-XQoYMqMTK8LvdxXYG3nZ448hOEQiglfqkJs1NOQV44cWnUrBc8PkAOcXy20w0vlaXaVUearIOBhiXZ5V3ynxwA==" ;
        leafletScript.crossOrigin = "anonymous" ;
    
        // load the css
        head.appendChild( leafletLink ) ;
        head.appendChild( leafletDrawLink ) ;
  
        // load the scripts
        head.appendChild( leafletScript ) ;
        
        return (
          <>
            <Grid className="gridMarginAlt" container
                direction="row"
                justify="center"
                alignItems="center" xs={12}>
          
                <TextField 
                    id={ this.getLocationNameId()}
                    className="textFieldAlt" 
                    InputProps={{ readOnly: true, }}
                    label="Location Name/Address"
                    variant="filled"
                    name="event-location-name"
                    value={ this._event.location.name }
                    InputLabelProps={{ shrink: true }} 
                    onInput={ this.handleAddressChange }
                    style={{ width: '100%' }}
                />
            </Grid>
                
            <Grid className="gridMarginAlt" container
                direction="row"
                justify="center"
                alignItems="center" xs={12}>
                
                <Container id={ this.getMapId()} maxWidth="sm" style={{ height: '480px' }} />
              
            </Grid>
          </>
        )
    }
}

export default withStyles( useStyles, { withTheme: true })( EventMap ) ;
