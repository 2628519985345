import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from '@material-ui/core/FormControl';
//import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
//import CancelIcon from '@material-ui/icons/Cancel';
import Checkbox from '@material-ui/core/Checkbox';
import Popover from '@material-ui/core/Popover';
import { Select } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import List from '@material-ui/core/List';


class CreateEvent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            description: "",
            owner: "",
            members: [],
            rows: [],
            makeChannel: false,
            checked: false,
            channel: false,
            anchorEl: null,
            id: '',
            left: [],
            right: [],
            leftChecked: [],
            rightChecked: [],
            checked: [],
            groupOwner: ""
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handDelete = this.handleDelete.bind(this);
        this.handleSkip = this.handleSkip.bind(this);
        this.newGroup = this.newGroup.bind(this);
    }

    handleChange(e) { 
        this.setState({[e.target.name]: e.target.value});
    }

    handleSkip(){
        this.props.history.push('/userlist');
    }

    handleDelete(chipToDelete) {
        console.log(chipToDelete);
        let newMembers = this.state.members.filter((chip) => chip.key !== chipToDelete.key);
        this.setState({ members: newMembers });
    }

    handleSubmit(evt) {
        evt.preventDefault()
        const { name, description, owner, groupOwner } = this.state;
        const members = this.state.right;
        const makeChannel = this.state.channel;
        //console.log("owner: ", owner);
        axios.post('/api/groups', { name, description, owner, members, makeChannel, groupOwner })
            .then(res => {
               console.log(res);
            })
            .catch(err => {
                console.log(err);
            });
        //this.props.history.push('/importresource');
    }

    async componentDidMount(){
         await axios.get('/api/roster')
        .then(res => {
            if(res.data.user){
              const data = res.data.user;
              this.setState({ rows: data });
            }
        }); 
        //console.log("state: ", this.props.location.state.members);
        const members = [];
        const roster = this.props.location.state.members;
        roster.forEach((user) => {
            members.push(user['first name'] + " " + user['last name']);
        });
        //console.log(members);

        const currentUser = await axios.get('/api/session/');
        const ownerid = currentUser.data.user.id;
        this.setState({ groupOwner: ownerid, members: members });

        await this.setLists();
        /* const id = this.props.match.params.id;
        this.setState({ id: id }); */
    }

    newGroup = () => {
        const id = this.props.match.params.id;

        this.setState({ anchorEl: null, name: "", description: "", owner: "", members: [], checked: false, makeChannel: false, channel: false });
        //this.props.history.push('/createeventgroup/' + id);
    }

    setLists = () => {
        let fullRoster = [];
        for(const user of this.state.members){
            fullRoster.push(user);
        }
        //console.log(fullRoster);
        //let fullRoster = this.state.rows;
        let rightSide = this.state.right;
        let leftSide = fullRoster.filter((value) => !rightSide[rightSide.indexOf(value)]);
        let rightChecked = this.state.checked.filter((item) => rightSide.includes(item));
        let leftChecked = this.state.checked.filter((item) => leftSide.includes(item));
        this.setState({ left: leftSide });
        this.setState({ right: rightSide });
        this.setState({ rightChecked: rightChecked });
        this.setState({ leftChecked: leftChecked });
    }

    render() {
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: 180,
                    width: 150,
                },
            },
        };

        const handleToggle = (value) => {
            const currentIndex = this.state.checked.indexOf(value);
            const newChecked = this.state.checked;
            if(currentIndex === -1){
                newChecked.push(value);
            } else {
                newChecked.splice(currentIndex, 1);
            }
            this.setState({ checked: newChecked });
            this.setLists();
        };

        const handleAllRight = () => {
            let right = this.state.right.concat(this.state.left);
            this.setState({ right: right });
            this.setState({ left: [] });
        };

        const handleAllLeft = () => {
            let left = this.state.left.concat(this.state.right);
            this.setState({ left: left });
            this.setState({ right: [] });
        };

        const handleCheckedRight = () => {
            let checked = this.state.right.concat(this.state.leftChecked);
            let left = this.state.left;
            let toRemove = this.state.leftChecked;
            this.setState({ right: checked });
            let newLeft = left.filter((item) => left[left.indexOf(item)] !== toRemove[toRemove.indexOf(item)]);
            this.setState({ left: newLeft });
        };

        const handleCheckedLeft = () => {
            let checked = this.state.left.concat(this.state.rightChecked);
            let right = this.state.right;
            let toRemove = this.state.rightChecked;
            this.setState({ left: checked });
            let newRight = right.filter((item) => right[right.indexOf(item)] !== toRemove[toRemove.indexOf(item)]);
            this.setState({ right: newRight });
        };

        const customList = (items) => (
            <Paper>
                
                <List dense component="div" role="list" style={{ maxHeight: 400, height: 400, overflow: 'auto' }}>
                    {items.map((value) => {
                        const labelId = `transfer-list-item-${value}-label`;

                        return(

                            <ListItem key={value} role="listitem" button onClick={() => {handleToggle(value)} }>
                                <ListItemIcon>
                                    <Checkbox
                                        checked={this.state.checked.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId}}
                                        />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={value} />
                            </ListItem>
                        );
                    })}
                </List>
            </Paper>
        );

        return (
            <div >
            <form className="createForm" onSubmit={this.handleSubmit}>
                <Grid container spacing={3}>
                    <Grid container
                        direction="row"
                        justify="center"
                        alignItems="center" xs={12}>
                        <Typography variant="h4" >
                            Add a New Group:
            </Typography>
                    </Grid>
                    <Grid className="gridMarginAlt" container
                        direction="row"
                        justify="center"
                        alignItems="center" xs={12}>
                        <TextField
                            className="textFieldAlt"
                            id="createGroupName"
                            label="Group Name"
                            name="name"
                            value={this.state.name}
                            placeholder="Ex: General"
                            multiline
                            variant="outlined"
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid className="gridMarginAlt" container
                        direction="row"
                        justify="center"
                        alignItems="center" xs={12}>
                        <TextField
                            className="textFieldAlt"
                            id="createGroupDescription"
                            label="Description"
                            name="description"
                            value={this.state.description}
                            placeholder="Description of group"
                            multiline
                            variant="outlined"
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid className="gridMarginAlt" container
                        direction="row"
                        justify="center"
                        alignItems="center" xs={12}>
                        {/* <TextField
                            className="textFieldAlt"
                            id="createGroupOwner"
                            label="Owner"
                            name="owner"
                            placeholder="John Smith"
                            multiline
                            variant="outlined"
                            onChange={this.handleChange}
                        /> */}
                        <FormControl variant="outlined" style={{ width: "50%"}}>
                            <InputLabel htmlFor="outlined-owner">Owner</InputLabel>
                            <Select
                                labelId="select owner"
                                label="Owner"
                                id="owner"
                                name="owner"
                                input={<Input />}
                                variant="outlined"
                                value={this.state.owner}
                                onChange={this.handleChange}
                                MenuProps={MenuProps}
                                /* renderValue={(selected) => {
                                    <div>
                                        {selected.map((value) => {
                                            <Chip key={value} label={value} />
                                        })}
                                    </div>
                                }} */
                                >   
                                    {this.state.rows && this.state.rows.map((row) => (
                                        <MenuItem key={row.id} value={row.firstName + ' ' + row.lastName}>
                                            {row.firstName + ' ' + row.lastName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                    </Grid>
                    <Grid container spacing={2} justify="center" alightItems="space-around">
                                    <Grid item style={{width: '35%'}}>
                                        <InputLabel htmlFor="outlined-roster">Available Roster</InputLabel>
                                        {customList(this.state.left)}
                                        </Grid>
                                    <Grid item>
                                        <Grid container direction="column" alignItems="center">
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={handleAllRight}
                                                
                                                disabled={this.state.left.length === 0}
                                                aria-label="move all right"
                                            >
                                                {'>>'}
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                
                                                onClick={handleCheckedRight}
                                                disabled={this.state.leftChecked.length === 0}
                                                aria-label="move selected right"
                                            >
                                                &gt;
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            
                                            onClick={handleCheckedLeft}
                                            disabled={this.state.rightChecked.length === 0}
                                            aria-label="move selected left"
                                        >
                                            &lt;
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            
                                            onClick={handleAllLeft}
                                            disabled={this.state.right.length === 0}
                                            aria-label="move all left"
                                        >
                                            ≪
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item style={{width: '35%'}}>
                                <InputLabel htmlFor="outlined-roster">Group Roster</InputLabel>
                                    {customList(this.state.right)}
                                </Grid>
                            </Grid>
                    <Grid className="gridMarginAlt" container
                        direction="row"
                        justify="center"
                        alignItems="center" xs={12}>
                        <Grid item>
                            <Checkbox
                                checked={this.state.channel}
                                onChange={e => {
                                    this.setState({ channel: e.target.checked })
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography>Make this group a channel in chat?</Typography>
                        </Grid>
                    </Grid>
                    <Grid container
                        direction="row"
                        justify="center"
                        alignItems="center" xs={12}>
                        {/* <input value="Upload" type="submit"></input> */}
                        <Button variant="outlined"  size="large" aria-label="Add Group" type="submit" onClick={e => { this.setState({ anchorEl: e.currentTarget }); }}>
                            Create
                        </Button>
                        <Button variant="outlined"  size="large" aria-label="skip" onClick={this.handleSkip} >
                            Skip
                        </Button>
                    </Grid>
                </Grid>
                <Popover
                id={ this.state.anchorEl ? 'simple-popover' : undefined }
                open={ Boolean(this.state.anchorEl) }
                anchorEl={ this.state.anchorEl }
                onClose={e => {
                    this.setState({ anchorEl: false });
                }}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                }}
            >
                <Typography>Create another group?</Typography>
                <Grid container
                        direction="row"
                        justify="center"
                        alignItems="center" xs={12}>
                        {/* <input value="Upload" type="submit"></input> */}
                        <Button variant="outlined"  size="large" aria-label="Add Group" type="submit" onClick={this.newGroup}>
                            Yes
                        </Button>
                        <Button variant="outlined"  size="large" aria-label="skip" onClick={this.handleSkip} >
                            No
                        </Button>
                </Grid>
            </Popover>
            </form>
            </div>
        )
    }
}

export default CreateEvent; 
