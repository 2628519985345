import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import EventMap from './EventMap';
import {NavLink} from 'react-router-dom';

class CreateEventMap extends Component {
    _eventLocation = JSON.stringify({
        name: "",
        map_region: {
            lat: 0.0,
            lng: 0.0,
            deltaLat: 0.1,
            deltaLng: 0.1,
        },
        perimeter_polygon: [],
        points_of_interest: []
    });


    constructor(props) {
        super(props)
        this.state = {
            eventId: '',
            location: '',
            eventName: ""
        }
        //this.handleSubmit = this.handleSubmit.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);

        document.addEventListener("eventLocationChange", this.handleLocationChange, false);
    }

    handleLocationChange(e) {
        e.stopPropagation();

        this._eventLocation = e.detail.value;
    }

    componentDidMount(){
        const id = this.props.match.params.id;
        const name = this.props.match.params.name;
        this.setState({ eventId: id, eventName: name  });
    }

    handleSubmit = (evt) => {
        const id = this.state.eventId;
        const location = this._eventLocation;
        axios.put('/api/event/' + id, { location });
    }

    render(){
        return(
            <div>
                <form className="createForm" onSubmit={this.handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid container
                            direction="row"
                            justify="center"
                            alignItems="center" xs={12}>
                            <Typography variant="h4" >
                                Create Event Map:
                            </Typography>
                        </Grid>
                        <Grid container
                        direction="row"
                        justify="center"
                        alignItems="center" sx={12}>
                            <EventMap EventId={this.state.eventId} readOnly={false} />
                        </Grid>
                        <Grid container
                        direction="row"
                        justify="center"
                        alignItems="center" xs={12}>
                        {/* <input value="Upload" type="submit"></input> */}
                        <NavLink to={"/import/" + this.state.eventId + '/' + this.state.eventName} activeClassName="EventViewSelected">
                            <Button variant="outlined"  size="large" aria-label="Add Event" type="submit" >
                                Next
                            </Button>
                        </NavLink>
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }
}

export default CreateEventMap;
