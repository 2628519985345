import React, { Component } from 'react';

function Chat(channel) {
    return (
        <iframe 
          src="https://chat.joancusec.org/?layout=embedded" 
          frameborder="0"
          title="test frame"
          width="100%"
          height="580px"
          title={channel.title}>
        </iframe>
    ) 
}
        
        
export default Chat;

//code to switch through event channel rooms --> from rocketchat site

// <div onClick={() => {
// document.getElementsByTagName("iframe")[0].contentWindow.postMessage(
// { externalCommand: "go", path: "/channel/Evening-Party/?layout=embedded" },
// "http://chat.joancusec.org"
// );
// }}> Evening Party </div>
