import React, { Component } from 'react';
import { fade, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ConfirmDialog from './ConfirmDialog';
import { NavLink } from 'react-router-dom';
import { Chip } from '@material-ui/core';


const overideStyles = theme => ({
    table: {
      minWidth: 650,
    },
    tableDataStyle: {
      fontSize: '1em'
    },
    toolbarButtons: {
      marginRight: 'auto',
    },
    appHeader: {
      backgroundColor: '#192d56',
    },
    appBarButton: {
      color: 'white',
      border: '1px solid white',
      margin: '5px',
    },
    title: {
      float: 'left',
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
   
      marginRight: theme.spacing(2),
      marginLeft: 0,
   
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  });

  const StyledTableCell = withStyles((theme) => ({
      head: {
          backgroundColor: '#12779f',
          color: theme.palette.common.white,
          fontSize: '1.25em',
     
      },
      body: {
   
        fontSize: '1.25em',
      },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
      root: {
        color: 'slategray',
          '&:nth-of-type(odd)': {
              // backgroundColor: ''theme.palette.action.hover'',
              backgroundColor: '#F3F4F8',
          },
          '&:nth-of-type(even)': {
            backgroundColor: '#E6E8E9',
        },
        '&:hover': {
        backgroundColor: 'white',
        cursor: 'pointer',
      
        },
        '& a': {
          color: '#3d3d3d',
        },
        '&:hover a': {
          color: '#12779f',
        },
      },
  }))(TableRow);

class EventTable extends Component {
  constructor(props){
    super(props)
    this.state = {
      id: '',
      confirmOpen: false,
      rows: [],
      members: [],
      chipCount: 0,
      currentRow: null,
      checked: [],
      toDelete: [],
      ownerid: ''
    }
    this.onDelete = this.onDelete.bind(this);
  }
    
  //Deleting event 
  async componentDidMount() {
    const currentUser = await axios.get('/api/session/');
    const ownerid = currentUser.data.user.id;
    this.setState({ ownerid: ownerid });

    await axios.get('/api/groups/ownergroups/' + ownerid)
      .then(res => {
        let data = res.data.groups;
        this.setState({ rows: data });
      });

    this.state.rows.forEach(async (group) => {
      let groupid = group.id;
      await axios.post('/api/roster/grouproster', { groupid })
      .then(res => {
        if(res.data.users.length > 0){
          console.log("true");
          res.data.users.forEach((member) =>{
            let name = member.firstName + ' ' + member.lastName;
            let params = {"id": groupid, "name": name};
            let array = this.state.members.concat(params);
            this.setState({ members: array });
          });
        }
      });
    })
  }

  onDelete = () => {
    this.state.toDelete.forEach(item => {
      axios.delete('/api/groups/' + item);
    });

    this.updateList();
  }

  updateList = () => {
    axios.get('/api/groups/ownergroups/' + this.state.ownerid)
      .then(res => {
        const data = res.data.groups;
        //let test = [];
        this.setState({ rows: data });
        data.forEach(item => {
          let checkedItems = this.state.checked.concat(false);
          this.setState({ checked: checkedItems});
        });
      });
  }


  handleSubmit = event => {
    event.preventDefault();

  /* axios.delete('/api/event'+event.id)
    .then(res => {
      console.log(res);
      console.log(res.data);
    }) */
  }


  printMembers = (rowId) => {
    let members = this.state.members;
    let chips = [];
    members.forEach(async (member) => {
      if(member.id === rowId){
        
        if(chips.length <= 5 && chips.length != 0){
          await chips.push(member.name);
          {/* <Chip
                  size="small"
                  label={member.name}
                />; */}
        }
        if(chips.length === 6){
          await chips.push('...');
          /* return <Chip size="small" label="..." />; */
        }
      }
    });
    return chips;
  }

  render(){
    const { classes } = this.props;

    /* const countMembers2 = (rowId) => {
      let count = 0;
      this.state.members.forEach((member) => {
        if(member.id === rowId){
          count++;
        } 
      })
      return count;
    }; */

    

    return (
      <TableContainer component={Paper}>
        <AppBar position="static" className={classes.appHeader}>
        <Toolbar> 
          <Grid justify='space-between' container spacing={24}>
            <Grid item>
              <Typography variant="h4" className={classes.title}>
                List of Groups
              </Typography>
            </Grid>
            <Grid container direction="row" justify="flex-end" alignItems="center" xs={6}>
            <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
                <Button variant="outlined" className={classes.appBarButton} onClick={this.onDelete} >
                  <DeleteForeverOutlinedIcon fontSize="large" /> Delete
                </Button>
                <NavLink to="/creategroup"><Button variant="outlined" aria-label="Add Group" className={classes.appBarButton}>
                  <AddBoxOutlinedIcon fontSize="large"/>  Add Group
                </Button></NavLink>
                <ConfirmDialog
                    title="Delete Group?"
                    open={this.confirmOpen}
                    setOpen={this.setConfirmOpen}
                    onConfirm={null}
                  >
                    Are you sure you want to delete this group?
                  </ConfirmDialog>
            </Grid>
          </Grid>
        </Toolbar>
        </AppBar>
        <Table className={classes.table} aria-label="group list table">
          <TableHead>
            <TableRow>
                    <StyledTableCell>Select</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Description</StyledTableCell>
                    <StyledTableCell>Owner</StyledTableCell>
                    <StyledTableCell>Members</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.rows && this.state.rows.map((row, index) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell>
                  <Checkbox
                    key={index}
                    checked={this.state.checked[index]}
                    onChange={e => {
                      //console.log(e.target.checked);
              
                      this.setState({ checked: e.target.checked });
                      if(e.target.checked === true){
                        if(this.state.toDelete.some(item => row.id === item) === false){
                          let joined = this.state.toDelete.concat(row.id);
                          this.setState({ toDelete: joined });
                        }
                      }
                      if(e.target.checked === false){
                        if(this.state.toDelete.some(item => row.id === item)){
                          var array = [...this.state.toDelete];
                          var index = array.indexOf(row.id);

                          if(index !== -1){
                            array.splice(index, 1);
                            this.setState({ toDelete: array });
                          }
                        }
                      }
                    }
                   }/>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" >
                  <NavLink style={{ textDecoration: 'none' }} to={"/eventview/" + row.id} activeClassName="EventViewSelected">
                    {row.name}
                  </NavLink>
                </StyledTableCell>
                <StyledTableCell>
                  <NavLink style={{ textDecoration: 'none' }} to={"/eventview/" + row.id} activeClassName="EventViewSelected">
                    {row.description}
                  </NavLink>
                </StyledTableCell>
                <StyledTableCell>
                  <NavLink style={{ textDecoration: 'none' }} to={"/eventview/" + row.id} activeClassName="EventViewSelected">
                    {row.owner}
                  </NavLink>
                </StyledTableCell>
                <StyledTableCell>
                  {this.printMembers(row.id) && this.printMembers(row.id).map((member)=> {
                      <Chip size="small" label={member} />
                    })
                  }
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
export default withStyles(overideStyles, { withTheme: true })(EventTable);
