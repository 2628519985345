import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { Select } from '@material-ui/core';

class CreateEvent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            description: "",
            owner: "",
            members: [],
            rows: [],
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handDelete = this.handleDelete.bind(this);
    }

    handleChange(e) { 
        this.setState({[e.target.name]: e.target.value});
    }

    handleDelete(chipToDelete) {
        console.log(chipToDelete);
        let newMembers = this.state.members.filter((chip) => chip.key !== chipToDelete.key);
        this.setState({ members: newMembers });
    }

    async handleSubmit(evt) {
        evt.preventDefault()

        const currentUser = await axios.get('/api/session/');
        const groupOwner = currentUser.data.user.id;
        const { name, description, owner, members } = this.state;
        //console.log("member: ", members);
        axios.post('/api/groups', { name, description, owner, members, groupOwner })
            .then(res => {
               console.log(res);
            })
            .catch(err => {
                console.log(err);
            });
        this.props.history.push('/groups');
    }

    componentDidMount(){
        axios.get('/api/roster')
        .then(res => {
            if(res.data.user){
              const data = res.data.user;
              this.setState({ rows: data });
            }
        });
    }

    render() {
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: 180,
                    width: 150,
                },
            },
        };

        return (
            <div >
            <form className="createForm" onSubmit={this.handleSubmit}>
                <Grid container spacing={3}>
                    <Grid container
                        direction="row"
                        justify="center"
                        alignItems="center" xs={12}>
                        <Typography variant="h4" >
                            Add a New Group:
            </Typography>
                    </Grid>
                    <Grid className="gridMarginAlt" container
                        direction="row"
                        justify="center"
                        alignItems="center" xs={12}>
                        <TextField
                            className="textFieldAlt"
                            id="createGroupName"
                            label="Group Name"
                            name="name"
                            placeholder="Ex: General"
                            multiline
                            variant="outlined"
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid className="gridMarginAlt" container
                        direction="row"
                        justify="center"
                        alignItems="center" xs={12}>
                        <TextField
                            className="textFieldAlt"
                            id="createGroupDescription"
                            label="Description"
                            name="description"
                            placeholder="Description of group"
                            multiline
                            variant="outlined"
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid className="gridMarginAlt" container
                        direction="row"
                        justify="center"
                        alignItems="center" xs={12}>
                        {/* <TextField
                            className="textFieldAlt"
                            id="createGroupOwner"
                            label="Owner"
                            name="owner"
                            placeholder="John Smith"
                            multiline
                            variant="outlined"
                            onChange={this.handleChange}
                        /> */}
                        <FormControl variant="outlined" style={{ width: "50%"}}>
                            <InputLabel htmlFor="outlined-owner">Owner</InputLabel>
                            <Select
                                labelId="select owner"
                                label="Owner"
                                id="owner"
                                name="owner"
                                input={<Input />}
                                variant="outlined"
                                value={this.state.owner}
                                onChange={this.handleChange}
                                MenuProps={MenuProps}
                                /* renderValue={(selected) => {
                                    <div>
                                        {selected.map((value) => {
                                            <Chip key={value} label={value} />
                                        })}
                                    </div>
                                }} */
                                >   
                                    {this.state.rows && this.state.rows.map((row) => (
                                        <MenuItem key={row.id} value={row.firstName + ' ' + row.lastName}>
                                            {row.firstName + ' ' + row.lastName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                    </Grid>
                    <Grid className="gridMarginAlt" container
                        direction="row"
                        justify="center"
                        alignItems="center" xs={12}>
                        {/* <TextField
                            className="textFieldAlt"
                            id="createGroupOwner"
                            label="Owner"
                            name="owner"
                            placeholder="John Smith"
                            multiline
                            variant="outlined"
                            onChange={this.handleChange}
                        /> */}
                        <FormControl variant="outlined" style={{ width: "50%"}}>
                            <InputLabel htmlFor="outlined-members">Members</InputLabel>
                            <Select
                                labelId="select members"
                                label="Members"
                                id="members"
                                multiple
                                name="members"
                                input={<Input id='select-members-chip'/>}
                                variant="outlined"
                                value={this.state.members}
                                onChange={this.handleChange}
                                MenuProps={MenuProps}
                                renderValue={(selected) => (
                                    <div>
                                        {selected.map((value) => (
                                            
                                                <Chip 
                                                    label={value} 
                                                    /* deleteIcon={
                                                        <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
                                                    } 
                                                    onDelete={this.handleDelete(value)} */
                                                />
                                            
                                        ))}
                                    </div>
                                )} 
                                >
                                    
                                    {this.state.rows && this.state.rows.map((row) => (
                                        <MenuItem key={row.id} renderValue={row.id} value={row.firstName + ' ' + row.lastName}>
                                            {row.firstName + ' ' + row.lastName}
                                        </MenuItem>
                                    ))}
                                    
                                </Select>
                            </FormControl>
                    </Grid>
                    <Grid container
                        direction="row"
                        justify="center"
                        alignItems="center" xs={12}>
                        {/* <input value="Upload" type="submit"></input> */}
                        <Button variant="outlined"  size="large" aria-label="Add Group" type="submit" >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </form>
            </div>
        )
    }
}

export default CreateEvent; 
