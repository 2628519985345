import React, { Component } from 'react';

function Weather( props ) {
    const {
        lat,
        lon
    } = props ;
    
    if ( lat == NaN && lon == NaN ) {
        return ( <></> ) ;
    }
    else {
        return (
            <iframe
              src={ `https://forecast.weather.gov/MapClick.php?lat=${ lat }&lon=${ lon }#.YLj1wy1h1oM` } 
              frameborder="0"
              title="test frame"
              width="100%"
              height="580px"
            />
        ) ;
    }
}
        
        
export default Weather;
