import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
//import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import List from '@material-ui/core/List'
import EvidentHelper from '../scripts/evidentHelper';
import { getFormattedDateTime, getFormattedDate } from '../FormatDate';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { ListItemText, ListItem } from '@material-ui/core';

const useStyles = theme => ({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 10,
    },
    pos: {
        marginBottom: 12,
    },
    linkMargin: {
        margin: '5px',
    },
    btnStyle: {
        textDecoration: 'none',
    },
});

class EventCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            row: [],
            id: '',
            firstName: '',
            lastName: '',
            middleInitial: '',
            email: '',
            phoneNumber: "",
            supervisor: '',
            role: '',
            rank: "",
            organization: '',
            address: "",
            city: "",
            state: "",
            zipCode: "",
            birthdate: "",
            sex: '',
            groups: []
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onSendVerificationRequest = this.onSendVerificationRequest.bind(this);
        this.onCheckVerificationStatus = this.onCheckVerificationStatus.bind(this);
    }

    onSubmit = () => {
        const params = JSON.stringify({
            firstName: this.state.firstName,
            middleInitial: this.state.middleInitial,
            lastName: this.state.lastName,
            email: this.state.email,
            phoneNumber: this.state.phoneNumber,
            supervisor: this.state.supervisor,
            role: this.state.role,
            organization: this.state.organization,
            address: this.state.address,
            city: this.state.city,
            state: this.state.state,
            zipCode: this.state.zipCode,
            birthdate: this.state.birthdate,
            sex: this.state.sex
        });
    }

    handleChange = (evt) => {
        evt.preventDefault();
        this.setState({ [evt.target.name]: evt.target.value });
    }

    onSendVerificationRequest = () =>
    {
        const data = { 
            id : this.state.id,
            firstName: this.state.row.firstName, 
            lastName: this.state.row.lastName, 
            email: this.state.row.email };

        //console.log(data);

        axios.post('/api/roster/submitVerification', data)
            .then(res => {
              const respData = res.data.event;
              console.log('Send verification respond: ' + respData);
              this.setState({ row: respData });
            });
    } 

    onCheckVerificationStatus = () =>
    {
        const data = { 
            id : this.state.id,
            verificationId: this.state.row.verification.verificationId };

        axios.post('/api/roster/checkVerificationStatus', data)
            .then(res => {
                console.log('Check verification status respond: ' + res.data.event);
                const respData = res.data.event;
                const row = {...this.state.row };
                row.verification.status = respData;
                this.setState({ row: row });
            });
    } 

    getVerificationStatus = (userInfo) => {
        console.log(userInfo);
        if (userInfo.verification)
        {
          // Mapper is needed for proper statuses
          return EvidentHelper.mapStatus(userInfo.verification.status);
        }  
    }

    async componentDidMount() {
        const id = this.props.match.params.id;
        this.setState({ id: id });
        const groups = [];

        await axios.get('/api/roster/' + id)
            .then(res => {
                const data = res.data.event;
                this.setState({ row: data });
            });

        await axios.get('/api/roster/usergroups/' + id)
            .then(async data => {
                await data.data.groups.forEach(async (group) => {
                    const g = await axios.get('/api/groups/' + group.groupid);
                    groups.push([g.data.group]);
                });
            })
            .catch(err => {
                console.log(err);
            });

            this.setState({ groups: groups });
    }

    render() {
        const { classes } = this.props;

        const notVerified = !this.state.row.verification;
        
        // Needs to verify list of provided statuses
        const verificationInProgress = notVerified ? false : this.state.row.verification.status !== 'shared';

        return (
            <Card className={classes.root} variant="outlined">
                <CardContent justify='center'>
                    <form>
                        <Grid>
                            <Grid container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <Typography variant="h4" >
                                    User Details:
                         </Typography>
                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <TextField className="textFieldAlt" InputProps={{ readOnly: true, }} label="First Name" variant="filled" name="firstName" value={this.state.row.firstName} InputLabelProps={{ shrink: true }} onInput={this.handleChange} />
                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <TextField className="textFieldAlt" InputProps={{ readOnly: true, }} label="Middle Initial" variant="filled" name="middleInitial" value={this.state.row.middleInitial} InputLabelProps={{ shrink: true }} onInput={this.handleChange} />
                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <TextField className="textFieldAlt" InputProps={{ readOnly: true, }} label="Last Name" variant="filled" name="lastName" value={this.state.row.lastName} InputLabelProps={{ shrink: true }} onInput={this.handleChange} />
                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <TextField className="textFieldAlt" InputProps={{ readOnly: true, }} label="Email" variant="filled" name="email" value={this.state.row.email} InputLabelProps={{ shrink: true }} onInput={this.handleChange} />
                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <TextField className="textFieldAlt" InputProps={{ readOnly: true, }} label="Phone Number" variant="filled" name="phoneNumber" value={this.state.row.phoneNumber} InputLabelProps={{ shrink: true }} onInput={this.handleChange} />
                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <TextField className="textFieldAlt" InputProps={{ readOnly: true, }} label="Supervisor" variant="filled" name="supervisor" value={this.state.row.supervisor} InputLabelProps={{ shrink: true }} onInput={this.handleChange} />

                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <TextField className="textFieldAlt" InputProps={{ readOnly: true, }} label="Specialization" variant="filled" name="role" value={this.state.row.role} InputLabelProps={{ shrink: true }} onInput={this.handleChange} />

                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <TextField className="textFieldAlt" InputProps={{ readOnly: true, }} label="Organization" variant="filled" name="organization" value={this.state.row.organization} InputLabelProps={{ shrink: true }} onInput={this.handleChange} />

                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <TextField className="textFieldAlt" InputProps={{ readOnly: true, }} label="Street Address" variant="filled" name="address" value={this.state.row.address} InputLabelProps={{ shrink: true }} onInput={this.handleChange} />
                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <TextField className="textFieldAlt" InputProps={{ readOnly: true, }} label="City" variant="filled" name="city" value={this.state.row.city} InputLabelProps={{ shrink: true }} onInput={this.handleChange} />
                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <TextField className="textFieldAlt" InputProps={{ readOnly: true, }} label="State" variant="filled" name="state" value={this.state.row.state} InputLabelProps={{ shrink: true }} onInput={this.handleChange} />
                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <TextField className="textFieldAlt" InputProps={{ readOnly: true, }} label="Zip Code" variant="filled" name="zipCode" value={this.state.row.zipCode} InputLabelProps={{ shrink: true }} onInput={this.handleChange} />
                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <TextField className="textFieldAlt" InputProps={{ readOnly: true, }} label="Birthdate" variant="filled" name="birthdate" value={getFormattedDate(this.state.row.birthdate)} InputLabelProps={{ shrink: true }} onInput={this.handleChange} />

                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <TextField className="textFieldAlt" InputProps={{ readOnly: true, }} label="Sex" variant="filled" name="sex" value={this.state.row.sex} InputLabelProps={{ shrink: true }} onInput={this.handleChange} />

                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <TextField className="textFieldAlt" InputProps={{ readOnly: true, }} label="Date added" variant="filled" value={getFormattedDateTime(this.state.row.createdAt)} InputLabelProps={{ shrink: true }} />

                            </Grid>

                            <Grid className="gridMarginAlt" container
                                direction="row"
                                justify="center"
                                alignItems="center" xs={12}>
                                <TextField className="textFieldAlt" InputProps={{ readOnly: true, }} label="Verification Status" variant="filled" value={this.getVerificationStatus(this.state.row)} InputLabelProps={{ shrink: true }} />

                            </Grid>

                            <CardActions>
                                <Grid className="gridMarginAlt" container
                                    spacing={2}
                                    direction="row"
                                    justify="center"
                                    alignItems="center" xs={12}>
                                    <Grid item>
                                        <NavLink style={{ textDecoration: 'none' }} to='/userlist'>
                                            <Button color="secondary" size="large" variant="outlined">Cancel</Button>
                                        </NavLink>
                                    </Grid>
                                    <Grid item>
                                        <NavLink style={{ textDecoration: 'none' }} to={`/manageuser/${this.state.row.id}/${this.state.row.firstName}/${this.state.row.lastName}/${this.state.row.email}/${this.state.row.supervisor}/${this.state.row.role}/${this.state.row.organization}`}>
                                            <Button color="primary" size="large" variant="outlined" onClick={this.onSubmit}>Edit</Button>
                                        </NavLink>
                                    </Grid>
                                </Grid>

                                <Grid className="gridMarginAlt" container
                                    spacing={2}
                                    direction="row"
                                    justify="center"
                                    alignItems="center" xs={12}>
                                    <Grid item>
                                        <NavLink style={{ textDecoration: 'none' }} to='#'>
                                        {notVerified && <Button color="secondary" size="large" variant="outlined" onClick={this.onSendVerificationRequest}>Send Verification Request</Button>}
                                        {verificationInProgress && <Button color="secondary" size="large" variant="outlined" onClick={this.onCheckVerificationStatus}>Check Verification Status</Button>}
                                        </NavLink>
                                    </Grid>
                                </Grid>
                            </CardActions>
                        </Grid>
                    </form>
                </CardContent>
                {/*<CardActions>
                    <Button size="small">Event Details</Button>
                </CardActions>*/}
            </Card>
        )
    }
}
export default withStyles(useStyles, { withTheme: true })(EventCard);
