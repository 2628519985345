import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Clock from 'react-live-clock';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import Tabs from '@material-ui/core/Tabs';
import Chat from './Chat';
import Tab from '@material-ui/core/Tab';
import EventMap from './EventMap';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import EventHubTabView from './EventHubTabView' ;
import Button from '@material-ui/core/Button';
import './EventHub.css';

import image1 from './../media/mallmap.png';
import image2 from './../media/mallmap2.png';
import image3 from './../media/eventcontactlist.png';
//import image4 from './../media/capitalonearena2.jpg';
import thumb1 from './../media/thumb1.png';
import thumb2 from './../media/thumb2.png';
//import thumb4 from './../media/thumb4.jpg';

 

const useStyles = theme => ({
    table: {
        minWidth: 650,
    },
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        minWidth: 650,
    }, 
    imagelist: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden'
    }
});

/* const importAll = (r) => {
    return r.keys().map(r);
}; */

class EventHub extends Component {
    constructor(props) {
        super(props)
        this.state = {
            eventId: null,
            start: '',
            end: '',
            location: JSON.stringify({
                name: "",
                map_region: {
                    latitude: 0.0,
                    longitude:0.0,
                    latitudeDelta: 0.1,
                    longitudeDelta: 0.1,
                },
                perimeter_polygon: [],
                points_of_interest: []
            }),
        }
    }

    async getEventData( eventId ) {
        let id = ( await axios.get( 'api/event/nextEvent/' )).data.nextEvent.id ;
        
        if ( id ) {
            await axios.get( '/api/event/' + id )
            .then(res => {
                const data = res.data.event;
                let location = JSON.parse(data.location);
                let start = data.start && moment(data.start).format('HH:mm');
                let end = data.end && moment(data.end).format('HH:mm');
            
                this.setState({ eventId: data.id, eventName: data.name, location: location.name, start: start, end: end });
            });
        }
    }
    
    componentDidMount() {
        this.getEventData() ;
    }

    render() {
        const { classes } = this.props;
        
        return (
            <div>
                <Grid container justify="center"><h1 id="fontStyle">Event Hub</h1></Grid>

                
                <Grid container direction="column" justify="space-evenly" alightItems="center" xs={12}>
                    
                    <Grid item style={{width: '100%' }}>
                        <Paper variant="outlined">
                            <Grid container direction="row" justify="space-evenly" alignItems="center" xs={12}>
                    
                                <Grid item>
                                    <h2 id="fontStyle">Active Event:</h2>
                                    <h4 id="fontStyle">{this.state.eventName + ' at ' + this.state.location + ' from ' + this.state.start + ' until ' + this.state.end}</h4>
                                </Grid>
                                <Grid item>
                                    <p id="eventHub">
                                        <h6>
                                            LIVE ALERTS
                                        </h6>
                                    </p>
                                </Grid>
                                <Grid item>
                                    <h2 id="fontStyle"><Clock 
                                        format={'h:mm:ssa'}
                                        ticking={true}
                                        style={{fontSize: '1.5em'}}/></h2>
                                </Grid>
                    
                            </Grid>
                        </Paper>
                    </Grid>
                
                    <Grid item >
                        <Grid container direction="row" justify="space-evenly" alignItems="center" xs={14}>
                            <Grid item style={{ width: '50%' }}>
                                <EventHubTabView key={ Date.now() } eventId={ this.state.eventId }/>
                            </Grid>
                            <Grid item style={{ width: '50%' }}>
                                <EventHubTabView key={ Date.now() } eventId={ this.state.eventId }/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(useStyles, { withTheme: true })(EventHub);