import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PublishIcon from '@material-ui/icons/Publish';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import axios from 'axios';


const overideStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,

    },
    grids: {
        margin: '15px'
    },
    textField: {
        width: '25%',
    },
}));

 
class ImportUsers extends Component {

    constructor(props) {
        super(props)
        this.state = {
            id: '',
            firstName: '',
            middleInitial: '',
            lastName: '',
            rank: '',
            email: '',
            phoneNumber: '',
            supervisor: '',
            role: '',
            organization: '',
            address: '',
            city: '',
            state: '',
            zipCode: '',
            birthdate: '',
            sex: '',
            inSystem: ''
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
        const cid = this.props.match.params.id;
        const firstName = this.props.match.params.firstName || '';
        const middleInitial = this.props.match.params.middleInitial || '';
        const lastName = this.props.match.params.lastName || '';
        const rank = this.props.match.params.rank || '';
        const email = this.props.match.params.email || '';
        const phoneNumber = this.props.match.params.phoneNumber || '';
        const supervisor = this.props.match.params.supervisor || '';
        const role = this.props.match.params.role || '';
        const organization = this.props.match.params.organization || '';
        const address = this.props.match.params.address || '';
        const city = this.props.match.params.city || '';
        const state = this.props.match.params.state || '';
        const zipCode = this.props.match.params.zipCode || '';
        const birthdate = this.props.match.params.birthdate || '';
        const sex = this.props.match.params.sex || '';
        const inSystem = this.props.match.params.inSystem || '';
        this.setState({ id: cid, firstName: firstName, middleInitial: middleInitial, lastName: lastName, rank: rank, email: email, phoneNumber: phoneNumber, supervisor: supervisor, role: role, organization: organization, address: address, city: city, state: state, zipCode: zipCode, birthdate: birthdate, sex: sex, inSystem: inSystem });
    }

      async handleSubmit(evt) {
        evt.preventDefault();
        
        const formData = new FormData();
        const plan = document.querySelector('#myFile');
        
        if(plan.files[0]){
            formData.append("id", this.state.id);
            formData.append("firstName", this.state.firstName);
            formData.append("middleInitial", this.state.middleInitial);
            formData.append("lastName", this.state.lastName);
            formData.append("rank", this.state.rank);
            formData.append("email", this.state.email);
            formData.append("phoneNumber", this.state.phoneNumber);
            formData.append("supervisor", this.state.supervisor);
            formData.append("role", this.state.role);
            formData.append("organization", this.state.organization);
            formData.append("address", this.state.address);
            formData.append("city", this.state.city);
            formData.append("state", this.state.state);
            formData.append("zipCode", this.state.zipCode);
            formData.append("birthdate", this.state.birthdate);
            formData.append("sex", this.state.sex);
            formData.append("inSystem", this.state.inSystem);
            formData.append("json", plan.files[0]);

            await axios.post('/api/roster/upload/', formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            })
            .then(res => {
                if(res.status === 200){
                    //this.props.history.push('/userlist');
                    this.props.history.push({pathname: '/createimportgroup', state: { members: res.data.roster }});
                }
            });
    }

        //axios.get('/api/upload/');
        

        /* if(plan){
            this.props.history.push('/userlist');
        } */
    }  
    
    render(){
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <form action="/api/roster/upload/" method="post" enctype="multipart/form-data" onSubmit={this.handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid container
                            direction="row"
                            justify="center"
                            alignItems="center" xs={12}>
                            <Typography variant="h4" className={classes.title}>
                                Import Users
                </Typography>
                        </Grid>
                        <Grid className={classes.grids} container
                            direction="row"
                            justify="center"
                            alignItems="center" xs={12}>
                            {/* <input type="file" id="myFile" name="file" /> */}

                            <FormControl variant="outlined">
                                <OutlinedInput
                                    id="myFile" type="file" name="file"
                                    endAdornment={<InputAdornment position="start">  <AttachFileIcon fontSize="small" /></InputAdornment>}

                                    inputProps={{
                                        'aria-label': 'Upload File',
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid container
                            direction="row"
                            justify="center"
                            alignItems="center" xs={12}>
                            {/* <input value="Upload" type="submit"></input> */}
                             <Button variant="outlined"  size="large" aria-label="Add Event" type="submit">
                                <PublishIcon fontSize="large" />  Upload
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }
}

export default withStyles(overideStyles, { withTheme: true })(ImportUsers);
