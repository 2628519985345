import React, { Component } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import SearchBar from "material-ui-search-bar";
import Faq from 'react-faq-component';
import { Link } from 'react-router-dom';

const useStyles = theme => ({
    table: {
        minWidth: 650,
    },
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        minWidth: 650,
    },
});

const data = {
    rows: [
        {
            title: "Who is OIEMS?",
            content: "Lorem ipsum dolor sit amet, consectetur"
        },
        {
            title: "What is OIEMS?",
            content: "Nunc maximus, magna at ultricies elementum"
        },
        {
            title: "Where is OIEMS?",
            content: "Curabitur laoreet, mauris vel blandit fringilla"
        },
        {
            title: "When is OIEMS?",
            content: "Sit amet justo donec enim diam vulputate ut"
        },
        {
            title: "Why is OIEMS?",
            content: "Tempor orci dapibus ultricies in iaculis nunc"
        }],
};

const styles = {
    rowTitleColor: "white",
    rowContentColor: "white",
    arrowColor: "#3f51b5",
    bgColor: "#282a2b",
    rowContentTextSize: "18px",
};

const config = {
    arrowIcon: "V",
    tabFocus: true,
    animate: true,
};

class Help extends Component {
    render(){
        return(
            <TableContainer component={Paper}>
                <AppBar position="static">
                    <Toolbar>
                        <Grid justify='space-between' container spacing={24}>
                            <Grid item>
                                <Typography variant="h5">
                                    OIEMS Help 
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Box component="span" m={1}>
                    <Typography variant="h5" align="center">How can we help? </Typography>
                        <SearchBar 
                            // value={this.state.value} 
                            // onChange={(newValue) => this.setState({ value: newValue })} 
                            // onRequestSearch={() => doSomethingWith(this.state.value)}
                            placeholder="Search"
                            style={{
                                border: 'solid #3f51b5 2px',
                                margin: '50px auto',
                                maxWidth: 800,
                            }}
                        />
                </Box>
                <Grid item m zeroMinWidth className="faq">
                    <Typography variant="h6" align="center">Frequently Asked Questions</Typography>
                    <div className="faqQuestions">
                        <Faq data={data} styles={styles} config={config}/>
                    </div>
                </Grid>
                <Box component="span" m={1}>
                    <div className="contact">
                        <div className="contactHeader">
                            <Typography variant="h5" align="center">Contact Us</Typography>
                        </div>
                        <Typography variant="subtitle1" align="center">Already a customer? Contact support services below so we can further help you!</Typography>
                        <div className="contactButton">
                            <Link to='/contactus' style={{ textDecoration: 'none' }}>
                                <Button
                                    style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    backgroundColor: "#3f51b5",
                                    color: "white",
                                    margin: "auto",
                                    }}
                                >Contact Support</Button>
                            </Link>
                        </div>
                     </div>
                </Box>
            </TableContainer>
        );
    }
}
export default Help;