import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { getFormattedDate } from '../FormatDate';


//import EventMap from './EventMap';

class CreateEvent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            name: "",
            type: "",
            start: "",
            end: "",
            status: ""
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    async handleSubmit(evt) {
        evt.preventDefault();

        const currentUser = await axios.get('/api/session/');
        const ownerId = currentUser.data.user.id;
        
        const location = JSON.stringify({
            name: "",
            map_region: {
              lat: 38.889260228566236,
              lng:-77.0501760013805,
              deltaLat: 0.003,
              deltaLng: 0.004,
            },
            perimeter_polygon: [],
            points_of_interest: []
        });
        const { name, type, description, archived, start, end, status } = this.state;
        axios.post('/api/event', { name, type, description, archived, start, end, location, status, ownerId })
            .then(res => {
                this.props.history.push('/manageevent/' + res.data.event.id + '/' + '0/' + name + '/' + description + '/' + type + '/' + start + '/' + end + '/' + location + '/' + status);
            });
    }
    render() {
        return (
            <div >
                <form className="createForm" onSubmit={this.handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid container
                            direction="row"
                            justify="center"
                            alignItems="center" xs={12}>
                            <Typography variant="h4" >
                                Add a New Event:
                        </Typography>
                    </Grid>
                    <Grid className="gridMarginAlt" container
                        direction="row"
                        justify="center"
                        alignItems="center" xs={12}>
                        <TextField
                            className="textFieldAlt"
                            id="createEventName"
                            label="Event Name"
                            name="name"
                            placeholder="Ex: Music Concert"
                            variant="outlined"
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid className="gridMarginAlt" container
                        direction="row"
                        justify="center"
                        alignItems="center" xs={12}>
                        <TextField
                            className="textFieldAlt"
                            id="createEventDescription"
                            label="Event Description"
                            rows={4}
                            name="description"
                            placeholder="Description"
                            multiline
                            variant="outlined"
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid className="gridMarginAlt" container
                        direction="row"
                        justify="center"
                        alignItems="center" xs={12}>
                        <TextField
                            className="textFieldAlt"
                            id="createEventType"
                            label="Type"
                            name="type"
                            multiline
                            variant="outlined"
                            onChange={this.handleChange}
                            select>
                                <MenuItem value="Sporting">Sporting</MenuItem>
                                <MenuItem value="Entertainment">Entertainment</MenuItem>
                                <MenuItem value="Political">Political</MenuItem>
                                <MenuItem value="Holiday">Holiday</MenuItem>
                                <MenuItem value="Misc">Misc.</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid className="gridMarginAlt" container
                        direction="row"
                        justify="center"
                        alignItems="center" xs={12}>
                        <TextField
                            className="textFieldAlt"
                            id="createEventStartDate"
                            name="start"
                            type="datetime-local"
                            defaultValue={getFormattedDate( ( new Date()).toString() )}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid className="gridMarginAlt" container
                        direction="row"
                        justify="center"
                        alignItems="center" xs={12}>
                        <TextField
                            className="textFieldAlt"
                            id="createEventEndDate"
                            name="end"
                            type="datetime-local"
                            defaultValue={getFormattedDate( ( new Date()).toString() )}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid className="gridMarginAlt" container
                        direction="row"
                        justify="center"
                        alignItems="center" xs={12}>
                        <TextField
                            className="textFieldAlt"
                            id="createEventRoles"
                            label="Status"
                            name="status"
                            placeholder="Ex: Security"
                            multiline
                            variant="outlined"
                            onChange={this.handleChange}
                            select>
                                <MenuItem value="Pre Planning">Pre Planning</MenuItem>
                                <MenuItem value="Planning">Planning</MenuItem>
                                <MenuItem value="In Progress">In Progress</MenuItem>
                                <MenuItem value="Complete">Complete</MenuItem>
                                <MenuItem value="Pending">Pending</MenuItem>
                                <MenuItem value="Active">Active</MenuItem>
                                <MenuItem value="Ready">Ready</MenuItem>
                        </TextField>
                    </Grid>
                   {/*  <Grid className="gridMarginAlt" container
                        direction="row"
                        justify="center"
                        alignItems="center" xs={12}>
                        <EventMap eventId={null} readOnly={false} />
                    </Grid> */}
                    <Grid container
                        direction="row"
                        justify="center"
                        alignItems="center" xs={12}>
                        {/* <input value="Upload" type="submit"></input> */}
                            <Button variant="outlined"  size="large" aria-label="Add Event" type="submit" >
                                Finish
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        )
    }
}

export default CreateEvent; 
