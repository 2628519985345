import React, { PureComponent, } from 'react';
import { NavLink } from 'react-router-dom';
import { fade, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import ConfirmDialog from './ConfirmDialog'
import axios from 'axios';
import EvidentHelper from '../scripts/evidentHelper';

const overideStyles = theme => ({
    table: {
      minWidth: 650,
    },
    toolbarButtons: {
      marginRight: 'auto',
    },
    appHeader: {
      backgroundColor: '#192d56',
    },
    appBarButton: {
      color: 'white',
      border: '1px solid white',
      margin: '5px',
    },
    title: {
      float: 'left',
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
   
      marginRight: theme.spacing(2),
      marginLeft: 0,
   
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  });

  const StyledTableCell = withStyles((theme) => ({
      head: {
          backgroundColor: '#12779f',
          color: theme.palette.common.white,
          fontSize: '1.25em',
      },
      body: {
        
          fontSize: '1.25em',
        
      },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
      root: {
          '&:nth-of-type(odd)': {
              // backgroundColor: ''theme.palette.action.hover'',
              backgroundColor: '#F3F4F8',
          },
          '&:nth-of-type(even)': {
            backgroundColor: '#E6E8E9',
        },
        
        '&:hover': {
          backgroundColor: 'white',
          cursor: 'pointer',
        
          },
          '& a': {
            color: '#3d3d3d',
          },
          '&:hover a': {
            color: '#12779f',
          },
      },
  }))(TableRow);

class UserTable extends PureComponent {
    constructor(props){
        super(props);
        this.state = {
            rows: [],
            setOpen: false,
            checked: [],
            toDelete: []
        }
        
        this.onDelete = this.onDelete.bind(this);
        this.isAllCheckboxChecked = this.isAllCheckboxChecked.bind( this ) ;
        this.onAllCheckboxChanged = this.onAllCheckboxChanged.bind( this ) ;
        this.checkboxChanged      = this.checkboxChanged.bind( this ) ;
    }

    componentDidMount() {
        this.loadUsers() ;
    }
    
    loadUsers = async () => {
        await axios.get('/api/roster')
          .then( result => {
            let data = result.data.user ;
            let checkedItems = [] ;
            
            if ( result.data.success ) {
            data.forEach(item => {
                  checkedItems.push( false ) ;
                }) ;
            }
            else {
                data = [] ;
            }
        
            this.setState({ 
                rows: data, 
                checked: checkedItems,
                toDelete: []
            });
            })
          .catch( err => {
              console.log( err ) ;
          });
      }

      setConfirmOpen = (state) => {
        this.setState( { confirmOpen: state });
      }

      getVerificationStatus = (userInfo) => {
        console.log(userInfo);
        if (userInfo.verification)
        {
          // Mapper is needed for proper statuses
          return EvidentHelper.mapStatus(userInfo.verification.status);
        }  
    }

      onDelete = () => {
        const { checked, rows, toDelete } = this.state ;
        const _this = this ;
        
        // console.log("user data is : ", this.state.toDelete);

        Promise.all(
            toDelete.map( rowId => {
                return axios.delete( '/api/roster/' + rowId ) ;
            })
        )
        .then( () => {
            _this.loadUsers() ;
        })
        .catch( () => {
            console.log.bind( console ) ;
            _this.loadUsers() ;
        }) ;
    }

    isAllCheckboxChecked = () => {
        const { rows, toDelete } = this.state ;
        
        return (( rows.length > 0 ) && ( rows.length == toDelete.length )) ;
    }
      
    onAllCheckboxChanged = ( e ) => {
        var checkedArray =  this.state.rows.map(( row, index ) => { return e.target.checked ; }) ;
        var toDeleteArray = (( e.target.checked ) ? this.state.rows.map(( row, index ) => { return row.id ; }) : [] ) ;
        
        this.setState({ 
          checked: checkedArray,
          toDelete: toDeleteArray
        }) ;
    }

    checkboxChanged = ( rowId, index, checked ) => {
        // let newChecked
        // if(e.target.checked === true) {
        //   newChecked = [...this.state.checked, row]
        // } else if (e.target.checked === false) {
        //   newChecked = this.state.checked.filter(item => {
        //     return (item !== row)
        //   });
        // }
        // this.setState({ checked: newChecked });

        //console.log(e.target.checked);
        let { toDelete } = this.state ;
        let rowIsChecked = this.state.checked ;
        rowIsChecked[ index ] = checked ;
        let array = [ ...toDelete ] ;
        let _this = this ;
        
        if( checked === true ) {
            if( toDelete.some( item => rowId === item ) === false ) {
                array.push( rowId ) ;
                _this.setState({ checked: rowIsChecked, toDelete: array }) ;
            }
        }
        if( checked === false ) {
            if( toDelete.some( item => rowId === item )) {
                var index = array.indexOf( rowId ) ;

                if( index != -1 ) {
                    array.splice( index, 1 ) ;
                    _this.setState({ checked: rowIsChecked, toDelete: array }) ;
                }
            }
        }
    }

    render() {
        const { classes } = this.props;
        const { checked, toDelete } = this.state ;
        
        var tableRows = [] ;
    
        if ( this.state.rows.length > 0 ) {
      
          tableRows = this.state.rows.map(( row, index ) => {
              let isChecked          = checked[ index ] ;
              let verificationStatus = this.getVerificationStatus( row ) ;
              
              return (
                  <StyledTableRow key={ row.name }> 
                    <StyledTableCell>
                      <Checkbox
                        key={index}
                        checked={ isChecked }
                        onChange={ e => { 
                          this.checkboxChanged( row.id, index, e.target.checked )
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell  component="th" scope="row">
                       <NavLink style={{ textDecoration: 'none' }} to={"/UserView/" + row.id}>
                         {row.lastName}, {row.firstName}
                       </NavLink>
                    </StyledTableCell>
                    <StyledTableCell >
                       <NavLink style={{ textDecoration: 'none' }} to={"/UserView/" + row.id}>
                         {row.rank}
                       </NavLink>
                    </StyledTableCell>
                    <StyledTableCell >
                      <NavLink style={{ textDecoration: 'none' }} to={"/UserView/" + row.id}>
                        {row.email}
                      </NavLink>
                    </StyledTableCell>
                    <StyledTableCell >
                      <NavLink style={{ textDecoration: 'none' }} to={"/UserView/" + row.id}>
                        {row.organization}
                      </NavLink>
                    </StyledTableCell>
                    <StyledTableCell >
                        <NavLink style={{ textDecoration: 'none' }} to={"/UserView/" + row.id}>
                          {row.specialization}
                        </NavLink>
                    </StyledTableCell>
                    <StyledTableCell >
                        <NavLink style={{ textDecoration: 'none' }} to={"/UserView/" + row.id}>
                          { verificationStatus }
                        </NavLink>
                    </StyledTableCell>
                  </StyledTableRow>
            ) ;
          });
        }

        let confirmationMessage ;
        switch( toDelete.length ) {
        case 0 :
            confirmationMessage = "No users selected." ;
            break ;
        case 1 :
            confirmationMessage = "Are you sure you want to delete this user?" ;
            break ;
        default :
            confirmationMessage = "Are you sure you want to delete these " + toDelete.length + " users?" ;
            break ; 
        }
        
        return (
            <TableContainer component={ Paper }>
            <AppBar position="static" className={ classes.appHeader }>
                <Toolbar>
                    <Grid justify='space-between' container spacing={ 24 }>
                        <Grid item>
                            <Typography variant="h4" className={ classes.title }>
                              List of Users
                            </Typography>
                        </Grid>
                        <Grid container direction="row" justify="flex-end" alignItems="center" xs={6}>
                            <div className={ classes.search }>
                                <div className={ classes.searchIcon }>
                                  <SearchIcon />
                                </div>
                                <InputBase
                                  placeholder="Search…"
                                  classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                  }}
                                  inputProps={{ 'aria-label': 'search' }}
                                />
                            </div>
                                  
                            <Button 
                                variant="outlined"
                                style={ toDelete.length < 1 ? { display: 'none' } : {}}
                                className={ classes.appBarButton } 
                                onClick={ () => this.setConfirmOpen( true )}>
                                <DeleteForeverOutlinedIcon fontSize="large" /> 
                                Delete
                            </Button>
                                  
                            <NavLink to="/createuser">
                                <Button 
                                    variant="outlined" 
                                    aria-label="Add User" 
                                    className={ classes.appBarButton }>
                                    <AddBoxOutlinedIcon fontSize="large"/>
                                    Add User
                                </Button>
                            </NavLink>
                                  
                            <ConfirmDialog
                                title="Delete User?"
                                open={ this.state.confirmOpen }
                                setOpen={ this.setConfirmOpen }
                                onConfirm={ this.onDelete } >
                                { confirmationMessage }
                            </ConfirmDialog>
                        </Grid>                              
                    </Grid>
                </Toolbar>
            </AppBar>
            <Table className={ classes.table } aria-label="event list table">
            <TableHead>
                <StyledTableRow>
                    <StyledTableCell className={ classes.head }>
                        <Checkbox
                            id=''
                            checked={ this.isAllCheckboxChecked()}
                            onChange={ this.onAllCheckboxChanged }
                        />
                    </StyledTableCell>
                    <StyledTableCell className={ classes.head }>Name</StyledTableCell>
                    <StyledTableCell className={ classes.head }>Rank</StyledTableCell>
                    <StyledTableCell className={ classes.head }>Email</StyledTableCell>
                    <StyledTableCell className={ classes.head }>Organization</StyledTableCell>
                    <StyledTableCell className={ classes.head }>Specialization</StyledTableCell>
                    <StyledTableCell className={ classes.head }>Verification Status</StyledTableCell>
                        {/* <StyledTableCell>
                            < Button varient = 'outline' color='secondary' href="/adduser" >
                                Add User
                            </Button>
                        </StyledTableCell> */}
                </StyledTableRow>
            </TableHead>
            <TableBody>
              {/* maps through users (rows) in order to get index of each user for check functionality  */}
                { tableRows }
            </TableBody>
            </Table>
        </TableContainer>
        
        );
    }
}
export default withStyles(overideStyles, { withTheme: true })(UserTable);
