import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PublishIcon from '@material-ui/icons/Publish';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import axios from 'axios';

const overideStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,

    },
    grids: {
        margin: '15px'
    },
    textField: {
        width: '25%',
    },
}));


class ImportResource extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: 0
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFinish = this.handleFinish.bind(this);
    }

    componentDidMount() {
        const cid = this.props.match.params.id;
        console.log("the cid is " + cid);
        this.setState({ id: cid });
    }

    handleFinish = () => {
        this.props.history.push('/events');
    }

    handleSubmit(evt) {
        evt.preventDefault();

        const formData = new FormData();
        const resource = document.querySelector('#myFile');

        formData.append("id", this.state.id);
        for (let i = 0; i < resource.files.length; i++) {
            formData.append(`file[${i}]`, resource.files[i]);
        }

        // formData.append("json", resource.files[0]);

        axios.post('/api/upload_file/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        axios.get('/api/upload_file/');

        if (resource) {
            this.props.history.push('/events');
        }
    }


    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <form action="/api/upload_file/" method="post" enctype="multipart/form-data" onSubmit={this.handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid container
                            direction="row"
                            justify="center"
                            alignItems="center" xs={12}>
                            <Typography variant="h4" className={classes.title}>
                                Import a Resource
                            </Typography>
                        </Grid>
                        <Grid className={classes.grids} container
                            direction="row"
                            justify="center"
                            alignItems="center" xs={12}>
                            {/* <input type="file" id="myFile" name="file" /> */}

                            <FormControl variant="outlined">
                                <OutlinedInput
                                    id="myFile" type="file" name="file"
                                    endAdornment={<InputAdornment position="start">  <AttachFileIcon fontSize="small" /></InputAdornment>}

                                    inputProps={{
                                        'aria-label': 'Upload File',
                                        'multiple': true
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid container
                            direction="row"
                            justify="center"
                            alignItems="center" xs={12}>
                            {/* <input value="Upload" type="submit"></input> */}
                            <Button variant="outlined" size="large" aria-label="Add Resource" type="submit">
                                <PublishIcon fontSize="large" />  Upload
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }
}

export default withStyles(overideStyles, { withTheme: true })(ImportResource);
